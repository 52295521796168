import { lazy, useState } from 'react';
import { styled } from '@mui/material/styles';
import { Button, Card, CardContent, Grid, Stack, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import Loadable from 'ui-component/Loadable';
import AnimateButton from 'ui-component/extended/AnimateButton';
import useAuth from 'hooks/useAuth';

// const Pricing = Loadable(lazy(() => import('views/PBXCMP/common/Pricing')));

const CardStyle = styled(Card)(({ theme }) => ({
    background: theme.palette.mode === 'dark' ? theme.palette.dark[800] : theme.palette.primary.light,
    marginTop: '16px',
    marginBottom: '16px',
    overflow: 'hidden',
    position: 'relative',
    '&:after': {
        content: '""',
        position: 'absolute',
        width: '200px',
        height: '200px',
        border: '19px solid ',
        borderColor: theme.palette.mode === 'dark' ? theme.palette.primary.main : theme.palette.primary.main,
        borderRadius: '50%',
        top: '50px',
        right: '-150px'
    },
    '&:before': {
        content: '""',
        position: 'absolute',
        width: '200px',
        height: '200px',
        border: '3px solid ',
        borderColor: theme.palette.mode === 'dark' ? theme.palette.primary.main : theme.palette.primary.main,
        borderRadius: '50%',
        top: '100px',
        right: '-90px'
    }
}));

const BuyNowCard = () => {
    const { defaultRole, isLoggedIn, user } = useAuth();
    const [open, setOpen] = useState(false);
    const navigate = useNavigate();

    // const handleClose = () => {
    //     setOpen(!open);
    // };

    const handleBuyNow = () => {
        if ((defaultRole === 'free' || defaultRole === 'anon') && !isLoggedIn) {
            navigate('register', { replace: true });
        } else {
            setOpen(!open);
        }
    };

    return (
        <>
            <CardStyle>
                <CardContent>
                    <Grid container direction="column" spacing={1}>
                        <Grid item>
                            <Typography variant="h4">Upgrade your plan</Typography>
                        </Grid>
                        <Grid item>
                            <Stack direction="row">
                                <AnimateButton>
                                    <Button variant="contained" sx={{ boxShadow: 'none' }} onClick={handleBuyNow}>
                                        Buy Now
                                    </Button>
                                </AnimateButton>
                            </Stack>
                        </Grid>
                    </Grid>
                </CardContent>
            </CardStyle>
            {/* {defaultRole === 'free' && user.isEmailVerfied && <Pricing open={open} handleClose={handleClose} />} */}
        </>
    );
};

export default BuyNowCard;
