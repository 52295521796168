/* eslint-disable import/prefer-default-export */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    securityGuardInfo: {},
    studentDetailsSC: {},
    todaysChildVisitor: {},
    studentDetailsByMN: {}
};

export const securityGuardWPSlice = createSlice({
    name: 'managment',
    initialState,
    reducers: {
        setSecurityGuardInfo: (state, action) => {
            state.securityGuardInfo = action.payload;
        },
        setStudentDetailsSC: (state, action) => {
            state.studentDetailsSC = action.payload;
        },
        setTodaysChildVisitor: (state, action) => {
            state.todaysVisitor = action.payload;
        },
        setStudentDetailsByMN: (state, action) => {
            state.studentDetailsByMN = action.payload;
        }
    }
});

export const { setSecurityGuardInfo, setTodaysChildVisitor, setStudentDetailsSC, setStudentDetailsByMN } = securityGuardWPSlice.actions;

export default securityGuardWPSlice.reducer;
