/* eslint-disable no-await-in-loop */
/* eslint-disable no-plusplus */
/* eslint-disable no-undef */
/* eslint-disable array-callback-return */
import { ApolloClient } from '@apollo/client';
import {
    eachWeekendOfMonth,
    eachWeekendOfYear,
    format,
    getDate,
    getDaysInMonth,
    getDaysInYear,
    getMonth,
    getYear,
    isSunday
} from 'date-fns';
import { capitalize, ceil, floor, join, map, omit, truncate, words } from 'lodash';
import { customAlphabet } from 'nanoid';
import { cache } from '../cache';
import Compressor from 'compressorjs';

import { getDocument } from 'pdfjs-dist';

import 'pdfjs-dist/build/pdf.worker.mjs';

const day = 1;
/* eslint-disable import/prefer-default-export */
export const sundaysInMonth = (date) => {
    const sundaysOfTheMonth = [];
    const weekendOfTheMonth = eachWeekendOfMonth(date);
    weekendOfTheMonth.map((item) => {
        if (isSunday(item)) {
            const dayResult = getDate(item);
            sundaysOfTheMonth.push(dayResult);
        }
    });
    return sundaysOfTheMonth;
};

export const sundaysInYear = (date) => {
    const sundaysOfTheYear = [];
    const weekendOfTheYear = eachWeekendOfYear(date);
    weekendOfTheYear.map((item) => {
        if (isSunday(item)) {
            const dayResult = getDate(item);
            sundaysOfTheYear.push(dayResult);
        }
    });
    return sundaysOfTheYear;
};

export const getPercentageByInMonth = (classStartDate, holidays, totalPresent, selectedMonthSundays) => {
    const year = getYear(new Date(classStartDate));
    const month = getMonth(new Date());
    const noOfSunday = selectedMonthSundays || sundaysInMonth(new Date(year, month, day)).length;
    const daysInMonth = getDaysInMonth(new Date());
    const noOfWorkingDays = daysInMonth - (noOfSunday + holidays.length);
    const percentage = ceil((totalPresent / noOfWorkingDays) * 100, 2);
    return percentage;
};

export const getPercentageInYear = (classStartDate, holidays, totalPresent) => {
    const daysInYear = getDaysInYear(new Date(classStartDate));
    const year = getYear(new Date(classStartDate));
    const month = getMonth(new Date());
    const noOfSundays = sundaysInYear(new Date(year, month, day)).length;
    const noOfHolidays = holidays;
    const noOfWorkdays = daysInYear - (noOfSundays + noOfHolidays);
    const percentage = floor((totalPresent / noOfWorkdays) * 100, 2);
    return percentage;
};

export const getAbsentCountAndPercentageOfYear = (classStartDate, holidays, totalPresent) => {
    const daysInYear = getDaysInYear(new Date(classStartDate));
    const year = getYear(new Date(classStartDate));
    const month = getMonth(new Date());
    const noOfSundays = sundaysInYear(new Date(year, month, day)).length;
    const noOfHolidays = holidays;
    const noOfWorkdays = daysInYear - (noOfSundays + noOfHolidays);
    const totalAbsent = noOfWorkdays - totalPresent;
    const percentage = ceil((totalAbsent / noOfWorkdays) * 100, 2);
    return { totalAbsent, percentage };
};

export const getCurrentSession = () => {
    const year = getYear(new Date());
    const yearPlusOne = String(year + 1);
    const removedLastTwoDigit = yearPlusOne.substr(yearPlusOne.length - 2);
    return `${year}-${removedLastTwoDigit}`;
};

export const getFireabaseErrorMsg = (error) => {
    const removedFirebase = error.replace('Firebase:', '');
    const removedAuthText = removedFirebase.replace(/ *\([^)]*\) */g, '');
    const removedDotFromLast = removedAuthText.replace(/ *\([^)]*\) */g, '').substring(0, removedAuthText.length - 1);
    return removedDotFromLast;
};

export const getRoleName = (defaultRole) => {
    let role;

    if (defaultRole === 'admin') {
        role = 'lsbadmin';
    } else if (defaultRole === 'editor') {
        role = 'editor';
    } else if (defaultRole === 'connector') {
        role = 'lsbap';
    } else if (defaultRole === 'dsa') {
        role = 'lsbmtf';
    } else if (defaultRole === 'backoffice') {
        role = 'lsbbof';
    } else if (defaultRole === 'telecaller') {
        role = 'lsbsr';
    } else if (defaultRole === 'free') {
        role = 'lsbfree';
    }

    return role;
};

export const client = new ApolloClient({ cache });

export const fullName = (first, middle, last) => {
    let fName;
    if (first && middle && last) {
        fName = `${first} ${middle} ${last}`;
    } else if (first && middle) {
        fName = `${first} ${middle}`;
    } else if (first && last) {
        fName = `${first} ${last}`;
    } else if (first) {
        fName = first;
    } else {
        fName = '';
    }

    return fName;
};

function createFeeData(rollNo, studentName, monthlyFeesStatus, monthlyTPFeesStatus, studentId, transportId, lastPaymentDate) {
    return {
        rollNo,
        studentName,
        monthlyFeesStatus,
        monthlyTPFeesStatus,
        studentId,
        transportId,
        lastPaymentDate
    };
}

export const getLabel = (fee) => {
    if (fee) return fee;
    return 'Due';
};

export const getColor = (fee, totalFees) => {
    if (fee >= totalFees && totalFees) {
        return 'success';
    }
    if (!fee) {
        return 'error';
    }
    return 'warning';
};

// export const formatClassFee = (classFee, fees) => {
//     const apr = { monthName: 'April', feeStatus: getLabel(classFee?.apr), chipColor: getColor(classFee?.apr, fees) };
//     const may = classFee?.may ? { monthName: 'May', feeStatus: classFee?.may } : { monthName: 'May', feeStatus: 'Due' };
//     const jun = classFee?.jun ? { monthName: 'June', feeStatus: classFee?.jun } : { monthName: 'June', feeStatus: 'Due' };
//     const jul = classFee?.jul ? { monthName: 'July', feeStatus: classFee?.jul } : { monthName: 'July', feeStatus: 'Due' };
//     const aug = classFee?.aug ? { monthName: 'August', feeStatus: classFee?.aug } : { monthName: 'August', feeStatus: 'Due' };
//     const sep = classFee?.sep ? { monthName: 'September', feeStatus: classFee?.sep } : { monthName: 'September', feeStatus: 'Due' };
//     const oct = classFee?.oct ? { monthName: 'October', feeStatus: classFee?.oct } : { monthName: 'October', feeStatus: 'Due' };
//     const nov = classFee?.nov ? { monthName: 'November', feeStatus: classFee?.nov } : { monthName: 'November', feeStatus: 'Due' };
//     const dec = classFee?.dec ? { monthName: 'December', feeStatus: classFee?.dec } : { monthName: 'December', feeStatus: 'Due' };
//     const jan = classFee?.jan ? { monthName: 'January', feeStatus: classFee?.jan } : { monthName: 'January', feeStatus: 'Due' };
//     const feb = classFee?.feb ? { monthName: 'February', feeStatus: classFee?.feb } : { monthName: 'February', feeStatus: 'Due' };
//     const mar = classFee?.mar ? { monthName: 'March', feeStatus: classFee?.mar } : { monthName: 'March', feeStatus: 'Due' };
//     return [jan, feb, mar, apr, may, jun, jul, aug, sep, oct, nov, dec];
// };
export const formatMonthlyFee = (classFee, fees) => {
    const apr = { monthName: 'April', feeStatus: getLabel(classFee?.apr), chipColor: getColor(classFee?.apr, fees) };
    const may = { monthName: 'May', feeStatus: getLabel(classFee?.may), chipColor: getColor(classFee.may, fees) };
    const jun = { monthName: 'June', feeStatus: getLabel(classFee?.jun), chipColor: getColor(classFee.jun, fees) };
    const jul = { monthName: 'July', feeStatus: getLabel(classFee?.jul), chipColor: getColor(classFee.jul, fees) };
    const aug = { monthName: 'August', feeStatus: getLabel(classFee?.aug), chipColor: getColor(classFee.aug, fees) };
    const sep = { monthName: 'September', feeStatus: getLabel(classFee?.sep), chipColor: getColor(classFee.sep, fees) };
    const oct = { monthName: 'October', feeStatus: getLabel(classFee?.oct), chipColor: getColor(classFee.oct, fees) };
    const nov = { monthName: 'November', feeStatus: getLabel(classFee?.nov), chipColor: getColor(classFee.nov, fees) };
    const dec = { monthName: 'December', feeStatus: getLabel(classFee?.dec), chipColor: getColor(classFee.dec, fees) };
    const jan = { monthName: 'January', feeStatus: getLabel(classFee?.jan), chipColor: getColor(classFee.jan, fees) };
    const feb = { monthName: 'February', feeStatus: getLabel(classFee?.feb), chipColor: getColor(classFee.feb, fees) };
    const mar = { monthName: 'March', feeStatus: getLabel(classFee?.mar), chipColor: getColor(classFee.mar, fees) };
    return [jan, feb, mar, apr, may, jun, jul, aug, sep, oct, nov, dec];
};

const formatTransportFee = (trnasportFee) => {};

export const formatFeeDetails = (reacord, studentId = false) => {
    const sessionFeeListLc = [];
    reacord.forEach((item) => {
        const firstName = item?.school_student?.first_name ? item.school_student.first_name : '';
        const middleName = item?.school_student?.middle_name ? item.school_student.middle_name : '';
        const lastName = item?.school_student?.last_name ? item.school_student.last_name : '';
        const lastPaymentDate = item?.school_student?.last_name ? item.class_fee.update_at : '';
        const apr =
            studentId || item?.class_fee?.apr
                ? // eslint-disable-next-line no-nested-ternary
                  { monthName: 'April', feeStatus: studentId ? (item.apr ? item.apr : 'Due') : item?.class_fee?.apr }
                : { monthName: 'April', feeStatus: 'Due' };
        const aprTP = item?.transport_fee?.apr
            ? { monthName: 'April', feeStatus: item?.transport_fee?.apr }
            : { monthName: 'April', feeStatus: 'Due' };
        const may =
            studentId || item?.class_fee?.may
                ? // eslint-disable-next-line no-nested-ternary
                  { monthName: 'May', feeStatus: studentId ? (item.may ? item.may : 'Due') : item?.class_fee?.may }
                : { monthName: 'May', feeStatus: 'Due' };
        const mayTP = item?.transport_fee?.may
            ? { monthName: 'May', feeStatus: item?.transport_fee?.may }
            : { monthName: 'May', feeStatus: 'Due' };
        const jun =
            studentId || item?.class_fee?.jun
                ? // eslint-disable-next-line no-nested-ternary
                  { monthName: 'June', feeStatus: studentId ? (item.jun ? item.jun : 'Due') : item?.class_fee?.jun }
                : { monthName: 'June', feeStatus: 'Due' };
        const junTP = item?.transport_fee?.jun
            ? { monthName: 'June', feeStatus: item?.transport_fee?.jun }
            : { monthName: 'June', feeStatus: 'Due' };
        const jul =
            studentId || item?.class_fee?.jul
                ? // eslint-disable-next-line no-nested-ternary
                  { monthName: 'July', feeStatus: studentId ? (item.jul ? item.jul : 'Due') : item?.class_fee?.jul }
                : { monthName: 'July', feeStatus: 'Due' };
        const julTP = item?.transport_fee?.jul
            ? { monthName: 'July', feeStatus: item?.transport_fee?.jul }
            : { monthName: 'July', feeStatus: 'Due' };
        const aug =
            studentId || item?.class_fee?.aug
                ? // eslint-disable-next-line no-nested-ternary
                  { monthName: 'August', feeStatus: studentId ? (item.aug ? item.aug : 'Due') : item?.class_fee?.aug }
                : { monthName: 'August', feeStatus: 'Due' };
        const augTP = item?.transport_fee?.aug
            ? { monthName: 'August', feeStatus: item?.transport_fee?.aug }
            : { monthName: 'August', feeStatus: 'Due' };
        const sep =
            studentId || item?.class_fee?.sep
                ? // eslint-disable-next-line no-nested-ternary
                  { monthName: 'September', feeStatus: studentId ? (item.sep ? item.sep : 'Due') : item?.class_fee?.sep }
                : { monthName: 'September', feeStatus: 'Due' };
        const sepTP = item?.transport_fee?.sep
            ? { monthName: 'September', feeStatus: item?.transport_fee?.sep }
            : { monthName: 'September', feeStatus: 'Due' };
        const oct =
            studentId || item?.class_fee?.oct
                ? // eslint-disable-next-line no-nested-ternary
                  { monthName: 'October', feeStatus: studentId ? (item.oct ? item.oct : 'Due') : item?.class_fee?.oct }
                : { monthName: 'October', feeStatus: 'Due' };
        const octTP = item?.transport_fee?.oct
            ? { monthName: 'October', feeStatus: item?.transport_fee?.oct }
            : { monthName: 'October', feeStatus: 'Due' };
        const nov =
            studentId || item?.class_fee?.nov
                ? // eslint-disable-next-line no-nested-ternary
                  { monthName: 'November', feeStatus: studentId ? (item.nov ? item.nov : 'Due') : item?.class_fee?.nov }
                : { monthName: 'November', feeStatus: 'Due' };
        const novTP = item?.transport_fee?.nov
            ? { monthName: 'November', feeStatus: item?.transport_fee?.nov }
            : { monthName: 'November', feeStatus: 'Due' };
        const dec =
            studentId || item?.class_fee?.dec
                ? // eslint-disable-next-line no-nested-ternary
                  { monthName: 'December', feeStatus: studentId ? (item.dec ? item.dec : 'Due') : item?.class_fee?.dec }
                : { monthName: 'December', feeStatus: 'Due' };
        const decTP = item?.transport_fee?.dec
            ? { monthName: 'December', feeStatus: item?.transport_fee?.dec }
            : { monthName: 'December', feeStatus: 'Due' };
        const jan =
            studentId || item?.class_fee?.jan
                ? // eslint-disable-next-line no-nested-ternary
                  { monthName: 'January', feeStatus: studentId ? (item.jan ? item.jan : 'Due') : item?.class_fee?.jan }
                : { monthName: 'January', feeStatus: 'Due' };
        const janTP = item?.transport_fee?.jan
            ? { monthName: 'January', feeStatus: item?.transport_fee?.jan }
            : { monthName: 'January', feeStatus: 'Due' };
        const feb =
            studentId || item?.class_fee?.feb
                ? // eslint-disable-next-line no-nested-ternary
                  { monthName: 'February', feeStatus: studentId ? (item.feb ? item.feb : 'Due') : item?.class_fee?.feb }
                : { monthName: 'February', feeStatus: 'Due' };
        const febTP = item?.transport_fee?.feb
            ? { monthName: 'February', feeStatus: item?.transport_fee?.feb }
            : { monthName: 'February', feeStatus: 'Due' };
        const mar =
            studentId || item?.class_fee?.mar
                ? // eslint-disable-next-line no-nested-ternary
                  { monthName: 'March', feeStatus: studentId ? (item.mar ? item.mar : 'Due') : item?.class_fee?.mar }
                : { monthName: 'March', feeStatus: 'Due' };
        const marTP = item?.transport_fee?.mar
            ? { monthName: 'March', feeStatus: item?.transport_fee?.mar }
            : { monthName: 'March', feeStatus: 'Due' };

        sessionFeeListLc.push(
            createFeeData(
                item.roll_no,
                fullName(firstName, middleName, lastName),
                [jan, feb, mar, apr, may, jun, jul, aug, sep, oct, nov, dec],
                [janTP, febTP, marTP, aprTP, mayTP, junTP, julTP, augTP, sepTP, octTP, novTP, decTP],
                studentId || item.student_id,
                item.transport_id,
                lastPaymentDate
            )
        );
    });

    return sessionFeeListLc;
};

export const getGeneratedId = customAlphabet('0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz', 21);

export const getFormatedDate = (date) => {
    if (date) return format(new Date(date), 'dd/MM/yyyy');
    return '';
};

export const getFormatedDateTime = (date) => {
    if (date) return format(new Date(date), 'dd/MM/yyyy p');
    return '';
};

export const getClassNamePlusSection = (className, section) => {
    if (section) return `${className} - ${section}`;
    return className;
};

export const getPercentage = (obtained, total) => {
    const percentage = (obtained / total) * 100;
    const ceildData = percentage ? `${ceil(percentage, 2)} %` : '';
    return ceildData || '';
};

export const removeHyphensAndCapitalize = (str) => {
    const wordsLc = words(str, /[^-]+/g);
    const capitalizedWords = map(wordsLc, capitalize);
    return join(capitalizedWords, ' ');
};

export const compressImage = (file) =>
    new Promise((resolve, reject) => {
        // eslint-disable-next-line no-new
        new Compressor(file, {
            quality: 0.6,
            mimeType: 'image/jpeg',
            success(result) {
                resolve(result);
            },
            error(err) {
                reject(err);
            }
        });
    });

export const convertToWords = (number) => {
    const units = ['zero', 'one', 'two', 'three', 'four', 'five', 'six', 'seven', 'eight', 'nine'];
    const tens = ['ten', 'twenty', 'thirty', 'forty', 'fifty', 'sixty', 'seventy', 'eighty', 'ninety'];
    const teens = ['eleven', 'twelve', 'thirteen', 'fourteen', 'fifteen', 'sixteen', 'seventeen', 'eighteen', 'nineteen'];
    const scales = ['thousand', 'lakh', 'crore'];

    function capitalizeWords(words) {
        return map(words.split(' '), capitalize).join(' ');
    }

    function convertThreeDigitNumber(num) {
        let result = '';
        const hundred = Math.floor(num / 100);
        const remainder = num % 100;

        if (hundred > 0) {
            result += `${units[hundred]} hundred `;
        }

        if (remainder > 0) {
            if (remainder < 10) {
                result += units[remainder];
            } else if (remainder < 20) {
                result += teens[remainder - 11];
            } else {
                const ten = Math.floor(remainder / 10);
                const unit = remainder % 10;
                result += tens[ten - 1];

                if (unit > 0) {
                    result += ` ${units[unit]}`;
                }
            }
        }

        return result.trim();
    }

    if (number === 0) {
        return units[0];
    }

    const isNegative = number < 0;
    number = Math.abs(number);
    let result = '';

    // eslint-disable-next-line no-plusplus
    for (let i = 0; number > 0; i++) {
        const remainder = number % 1000;
        number = Math.floor(number / 1000);

        if (remainder > 0) {
            const words = convertThreeDigitNumber(remainder);

            if (i > 0) {
                result = `${words} ${scales[i - 1]} ${result}`;
            } else {
                result = words;
            }
        }
    }

    if (isNegative) {
        result = capitalizeWords(`negative ${result}`);
    } else {
        result = capitalizeWords(result);
    }

    return result.trim();
};

export const getAttendanceCountAndArray = (attendance, noDaysOfInCurrentMonth) => {
    const attenArray = [];
    let totalP = 0;
    let totalA = 0;
    let totalH = 0;
    let totalL = 0;
    const sanetizedAttendance = omit(attendance, '__typename');
    if (sanetizedAttendance)
        Object.keys(sanetizedAttendance).forEach((key, index) => {
            if (index < noDaysOfInCurrentMonth) {
                if (sanetizedAttendance[key] === 'P') {
                    attenArray.push('P');
                    totalP += 1;
                } else if (sanetizedAttendance[key] === 'A') {
                    attenArray.push('A');
                    totalA += 1;
                } else if (sanetizedAttendance[key] === 'H') {
                    attenArray.push('H');
                    totalH += 1;
                } else if (sanetizedAttendance[key] === 'L') {
                    attenArray.push('L');
                    totalL += 1;
                } else {
                    attenArray.push('NA');
                }
            }
        });
    return { attenArray, totalP, totalA, totalH, totalL };
};

export const getMonthNo = (monthName) => {
    if (monthName === 'jan') return 0;
    if (monthName === 'feb') return 1;
    if (monthName === 'mar') return 2;
    if (monthName === 'apr') return 3;
    if (monthName === 'may') return 4;
    if (monthName === 'jun') return 5;
    if (monthName === 'jul') return 6;
    if (monthName === 'aug') return 7;
    if (monthName === 'sep') return 8;
    if (monthName === 'oct') return 9;
    if (monthName === 'nov') return 10;
    if (monthName === 'dec') return 11;
    return null;
};

export const getMonthDays = (month, selectedYear) => {
    const currentYear = getYear(new Date());
    const year = selectedYear || currentYear;
    const days = getDaysInMonth(new Date(year, month));
    if (month === 0) return days;
    if (month === 1) return days;
    if (month === 2) return days;
    if (month === 3) return days;
    if (month === 4) return days;
    if (month === 5) return days;
    if (month === 6) return days;
    if (month === 7) return days;
    if (month === 8) return days;
    if (month === 9) return days;
    if (month === 10) return days;
    if (month === 11) return days;
    return days;
};

export const containsEnglish = (inputString) => {
    const regex = /english/i;
    return regex.test(inputString);
};

export const truncatedText = (text, maxLength) => {
    const truncatedText = truncate(text, {
        length: maxLength,
        omission: '...'
    });

    return truncatedText;
};

export const isPDF = (downloadURL) => {
    const fileExtension = downloadURL.split('.').pop().toLowerCase();
    return fileExtension === 'pdf';
};

export const getRoleLabel = (defaultRole) => {
    let role;

    if (defaultRole === 'dsa') {
        role = 'DSA';
    } else if (defaultRole === 'backoffice') {
        role = 'Back Office';
    } else if (defaultRole === 'televendor') {
        role = 'Televendor';
    } else if (defaultRole === 'dsaaccess') {
        role = 'DSA Access';
    } else if (defaultRole === 'connector') {
        role = 'Connector';
    } else if (defaultRole === 'demoaccess') {
        role = 'Demo Access';
    } else if (defaultRole === 'telecaller') {
        role = 'Telecaller';
    } else if (defaultRole === 'customer') {
        role = 'Customer';
    } else if (defaultRole === 'editor') {
        role = 'Editor';
    } else if (defaultRole === 'admin') {
        role = 'Admin';
    } else if (defaultRole === 'free') {
        role = 'Free';
    }

    return role;
};

export const getChipColor = (type) => {
    if (type === 1) {
        return 'success';
    }
    if (type === 2) {
        return 'error';
    }
    if (type === 3) {
        return 'warning';
    }
    if (type === 4) {
        return 'info';
    }
    if (type === 34) {
        return 'secondary';
    }
    return 'primary';
};

const stateList = [
    {
        name: 'Meghalaya',
        stateCode: '17'
    },
    {
        name: 'Haryana',
        stateCode: '06'
    },
    {
        name: 'Maharashtra',
        stateCode: '27'
    },
    {
        name: 'Goa',
        stateCode: '30'
    },
    {
        name: 'Manipur',
        stateCode: '14'
    },
    {
        name: 'Puducherry',
        stateCode: '34'
    },
    {
        name: 'Telangana',
        stateCode: '36'
    },
    {
        name: 'Odisha',
        stateCode: '21'
    },
    {
        name: 'Rajasthan',
        stateCode: '08'
    },
    {
        name: 'Punjab',
        stateCode: '03'
    },
    {
        name: 'Uttarakhand',
        stateCode: '05'
    },
    {
        name: 'Andhra Pradesh',
        stateCode: '28'
    },
    {
        name: 'Nagaland',
        stateCode: '13'
    },
    {
        name: 'Lakshadweep',
        stateCode: '31'
    },
    {
        name: 'Himachal Pradesh',
        stateCode: '02'
    },
    {
        name: 'Delhi',
        stateCode: '07'
    },
    {
        name: 'Uttar Pradesh',
        stateCode: '09'
    },
    {
        name: 'Andaman and Nicobar Islands',
        stateCode: '35'
    },
    {
        name: 'Arunachal Pradesh',
        stateCode: '12'
    },
    {
        name: 'Jharkhand',
        stateCode: '20'
    },
    {
        name: 'Karnataka',
        stateCode: '29'
    },
    {
        name: 'Assam',
        stateCode: '18'
    },
    {
        name: 'Kerala',
        stateCode: '32'
    },
    {
        name: 'Jammu and Kashmir',
        stateCode: '01'
    },
    {
        name: 'Gujarat',
        stateCode: '24'
    },
    {
        name: 'Chandigarh',
        stateCode: '04'
    },
    {
        name: 'Dadra and Nagar Haveli and Daman and Diu',
        stateCode: '26'
    },
    {
        name: 'Sikkim',
        stateCode: '11'
    },
    {
        name: 'Tamil Nadu',
        stateCode: '33'
    },
    {
        name: 'Mizoram',
        stateCode: '15'
    },
    {
        name: 'Bihar',
        stateCode: '10'
    },
    {
        name: 'Tripura',
        stateCode: '16'
    },
    {
        name: 'Madhya Pradesh',
        stateCode: '23'
    },
    {
        name: 'Chhattisgarh',
        stateCode: '22'
    },
    {
        name: 'Ladakh',
        stateCode: '38'
    },
    {
        name: 'West Bengal',
        stateCode: '19'
    }
];

export const getStateCode = (stateName) => stateList.find((item) => item.name === stateName).stateCode;

export const getPaymentStatus = (type) => {
    if (type) {
        return { label: 'Paid', color: 'success' };
    }
    if (type === false) {
        return { label: 'Failed', color: 'error' };
    }

    return { label: 'Pending', color: 'warning' };
};

export const isValidPAN = (pan) => {
    const panPattern = /^[A-Z]{5}[0-9]{4}[A-Z]$/;
    return panPattern.test(pan);
};

export const isValidPhone = (phone) => {
    const phonePattern = /^[6-9]\d{9}$/;
    return phonePattern.test(phone);
};

export const panPattern = /^[A-Z]{5}[0-9]{4}[A-Z]$/;
export const phonePattern = /^[6-9]\d{9}$/;
export const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
export const aadhaarPattern = /^\d{12}$/;
// export const gstinPattern = /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}[Z]{1}[0-9]{1}$/;

export const getChipLabelAndColor = (type) => {
    if (type === 19) {
        return { label: 'In Process', bgColor: '', color: 'warning', hoverBgColor: '', textColor: '#616161' };
    }
    if (type === 20) {
        return { label: 'Rejected', bgColor: '', color: 'error', hoverBgColor: '', textColor: '#ffffff' };
    }
    if (type === 21) {
        return { label: 'Approved', bgColor: '', color: 'success', hoverBgColor: '', textColor: '#ffffff' };
    }
    if (type === 26) {
        return { label: 'New Client', bgColor: '#176B87', color: 'success', hoverBgColor: '#12586f', textColor: '#ffffff' };
    }
    if (type === 27) {
        return { label: 'Old Client', bgColor: '#64CCC5', color: 'success', hoverBgColor: '#51aba5', textColor: '#ffffff' };
    }
    if (type === 28) {
        return { label: 'False Info', bgColor: '#ffcf90', color: 'success', hoverBgColor: '#ebbc91', textColor: '#774600' };
    }
    return { label: 'New', bgColor: '#6499E9', color: 'success', hoverBgColor: '#476da7', textColor: '#ffffff' };
};

export const pdfToImage = async (url, num) => {
    const fileExtension = url.split('.').pop().split(/#|\?/)[0];

    if (fileExtension === 'pdf') {
        const loadingTask = getDocument(url);
        let totalPages;

        try {
            const pdf = await loadingTask.promise;
            totalPages = pdf.numPages;
            const imageFilesArr = [];

            for (let i = 1; i <= totalPages; i++) {
                const page = await pdf.getPage(i);
                const viewport = page.getViewport({ scale: 2 });

                const canvas = document.createElement('canvas');
                const context = canvas.getContext('2d');
                canvas.height = viewport.height;
                canvas.width = viewport.width;
                const renderContext = {
                    canvasContext: context,
                    viewport
                };
                await page.render(renderContext).promise;

                const dataURL = canvas.toDataURL('image/png');
                const blob = await fetch(dataURL).then((res) => res.blob());
                const imageFile = new File([blob], `page_${i}.png`, { type: 'image/png' }); // Create File object
                imageFilesArr.push(imageFile);
            }

            return imageFilesArr;
        } catch (error) {
            console.error('Error while converting PDF to images:', error);
            alert(`Error while converting PDF to images: ${error.message}`);
        }
    } else {
        try {
            const response = await fetch(url);
            const blob = await response.blob();
            const file = new File([blob], `redgear.jpeg`, { type: blob.type });
            return [file];

            // const response = await fetch(url);
            // const blob = await response.blob();
            // const contentType = response.headers.get('content-type');
            // const fileExtension = contentType.split('/')[1];
            // const fileName = `redgear${num}.${fileExtension}`;
            // const file = new File([blob], fileName, { type: blob.type });
            // return [file];
        } catch (error) {
            alert(`Error while converting images: ${error.message}`);
        }
    }

    return undefined;
};

export const urlToFile = async (url, num) => {
    try {
        const response = await fetch(url);
        const blob = await response.blob();
        const contentType = response.headers.get('content-type');
        const fileExtension = contentType.split('/')[1];
        const fileName = `redgear${num}.${fileExtension}`;
        const file = new File([blob], fileName, { type: blob.type });
        return [file];
    } catch (error) {
        alert(`Error while converting images: ${error.message}`);
    }
    return undefined;
};

export const getPlanType = (type) => {
    let role;

    if (type === 'lsbmtf') {
        role = 'DSA Pro';
    } else if (type === 'lsbap') {
        role = 'Connector Plus';
    } else if (type === 'lsbsr') {
        role = 'TeleCaller Plus';
    } else if (type === 'lsbu') {
        role = 'Premium';
    } else if (type === 'lsbbof') {
        role = 'Backend User';
    } else if (type === 'dsaacs') {
        role = 'Backend User';
    } else {
        role = 'Free';
    }

    return role;
};
