import { AccountBalance, Category, Home, Inventory2 } from '@mui/icons-material';
import { FormattedMessage } from 'react-intl';

const editor = {
    id: 'editor',
    type: 'group',
    children: [
        {
            id: 'home',
            title: <FormattedMessage id="home" />,
            type: 'item',
            url: '/home',
            icon: Home,
            breadcrumbs: false
        },
        {
            id: 'bank-and-nbfc',
            title: <FormattedMessage id="bank-and-nbfc" />,
            type: 'item',
            url: '/bank-and-nbfc',
            icon: AccountBalance,
            breadcrumbs: false
        },
        {
            id: 'product-category',
            title: <FormattedMessage id="product-category" />,
            type: 'item',
            url: '/product-category',
            icon: Category,
            breadcrumbs: false
        },
        {
            id: 'products',
            title: <FormattedMessage id="products" />,
            type: 'item',
            url: '/products',
            icon: Inventory2,
            breadcrumbs: false
        }
    ]
};

export default editor;
