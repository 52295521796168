import { lazy } from 'react';
import AuthGuard from 'utils/route-guard/AuthGuard';
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';

const Account = Loadable(lazy(() => import('views/common/Account')));
const Profile = Loadable(lazy(() => import('views/common/Profile')));
const Dashboard = Loadable(lazy(() => import('views/dsa/Dashboard')));
const Leads = Loadable(lazy(() => import('views/dsa/Leads')));
const InProcess = Loadable(lazy(() => import('views/dsa/InProcess')));
const Approved = Loadable(lazy(() => import('views/dsa/Approved')));
const Disbursed = Loadable(lazy(() => import('views/dsa/Disbursed')));
const Employees = Loadable(lazy(() => import('views/dsa/Employees')));
const Accounts = Loadable(lazy(() => import('views/common/DSA/Employees/Accounts')));
const Cases = Loadable(lazy(() => import('views/common/DSA/Employees/Cases')));
const Settlement = Loadable(lazy(() => import('views/dsa/Payouts')));
const PayoutsByEmpID = Loadable(lazy(() => import('views/common/DSA/Payouts/PayoutsByEmpID')));
const Products = Loadable(lazy(() => import('views/dsa/Products')));
const Recharge = Loadable(lazy(() => import('views/dsa/Credits')));
const Orders = Loadable(lazy(() => import('views/common/DSA/Credits/Old/Orders')));
const Transactions = Loadable(lazy(() => import('views/common/DSA/Credits/Old/Transactions')));
const Rejected = Loadable(lazy(() => import('views/dsa/Rejected')));
const AllCases = Loadable(lazy(() => import('views/dsa/AllCases')));
const Permission = Loadable(lazy(() => import('views/dsa/Permission')));
const TeleCalling = Loadable(lazy(() => import('views/dsa/TeleCalling')));

const DSARoutes = {
    path: '/',
    element: (
        <AuthGuard>
            <MainLayout />
        </AuthGuard>
    ),
    children: [
        {
            path: '/account',
            element: <Account />
        },
        {
            path: '/profile',
            element: <Profile />
        },
        {
            path: '/home',
            element: <Dashboard />
        },
        {
            path: '/leads',
            element: <Leads />
        },
        {
            path: '/all-cases',
            element: <AllCases />
        },
        {
            path: '/inprocess',
            element: <InProcess />
        },
        {
            path: '/approved',
            element: <Approved />
        },
        {
            path: '/rejected',
            element: <Rejected />
        },
        {
            path: '/disbursed',
            element: <Disbursed />
        },
        {
            path: '/telecalling',
            element: <TeleCalling />
        },
        {
            path: '/employees',
            element: <Employees />
        },
        {
            path: '/employee/accounts',
            element: <Accounts />
        },
        {
            path: '/employee/cases',
            element: <Cases />
        },
        {
            path: '/payouts',
            element: <Settlement />
        },
        {
            path: '/payouts/cases',
            element: <Cases />
        },
        {
            path: '/payouts/total-payouts',
            element: <PayoutsByEmpID />
        },
        {
            path: '/products',
            element: <Products />
        },
        {
            path: '/credits',
            element: <Recharge />
        },
        {
            path: '/credits/orders',
            element: <Orders />
        },
        {
            path: '/credits/transactions',
            element: <Transactions />
        },
        {
            path: '/permission',
            element: <Permission />
        }
    ]
};

export default DSARoutes;
