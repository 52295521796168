import { Box, Grid } from '@mui/material';
import NavMotion from 'layout/NavMotion';
import { Outlet, useLocation } from 'react-router-dom';
import AppBar from 'ui-component/extended/AppBar';
import Footer from 'views/landing/Footer';

const MinimalLayout = () => {
    const location = useLocation();
    console.log(location.pathname);
    return (
        <>
            {location.pathname !== '/login' && location.pathname !== '/register' && location.pathname !== '/forgot' ? (
                <Grid container>
                    <Grid item xs={12}>
                        <AppBar />
                    </Grid>
                    <Grid item xs={12} sx={{ p: 1.5, backgroundColor: '#cee5daad', mt: { xs: 6.75, sm: 8.375 } }}>
                        <NavMotion>
                            <Outlet />
                        </NavMotion>
                    </Grid>
                    <Footer />
                </Grid>
            ) : (
                <Outlet />
            )}
        </>
    );
};

export default MinimalLayout;
