import { AccountBalance, Category } from '@mui/icons-material';
import { FormattedMessage } from 'react-intl';

const televendor = {
    id: 'televendor',
    type: 'group',
    children: [
        {
            id: 'home',
            title: <FormattedMessage id="home" />,
            type: 'item',
            url: '/home',
            icon: AccountBalance,
            breadcrumbs: false
        },
        {
            id: 'televendor-tab-1',
            title: <FormattedMessage id="televendor-tab-1" />,
            type: 'item',
            url: '/televendor-tab-1',
            icon: Category,
            breadcrumbs: false
        },
        {
            id: 'televendor-tab-2',
            title: <FormattedMessage id="televendor-tab-2" />,
            type: 'item',
            url: '/televendor-tab-2',
            icon: Category,
            breadcrumbs: false
        },
        {
            id: 'televendor-tab-3',
            title: <FormattedMessage id="televendor-tab-3" />,
            type: 'item',
            url: '/televendor-tab-3',
            icon: Category,
            breadcrumbs: false
        },
        {
            id: 'televendor-tab-4',
            title: <FormattedMessage id="televendor-tab-4" />,
            type: 'item',
            url: '/televendor-tab-4',
            icon: Category,
            breadcrumbs: false
        },
        {
            id: 'televendor-tab-5',
            title: <FormattedMessage id="televendor-tab-5" />,
            type: 'item',
            url: '/televendor-tab-5',
            icon: Category,
            breadcrumbs: false
        },
        {
            id: 'televendor-tab-6',
            title: <FormattedMessage id="televendor-tab-6" />,
            type: 'item',
            url: '/televendor-tab-6',
            icon: Category,
            breadcrumbs: false
        }
    ]
};

export default televendor;
