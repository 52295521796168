import { Box } from '@mui/material';
import LogoSection from '../LogoSection';
import CustomBottomNavigation from './MobileSection/CustomBottomNavigation';
import ProfileSection from './ProfileSection';
import useAuth from 'hooks/useAuth';

const Header = () => (
    <>
        <Box sx={{ display: { sm: 'block' } }}>
            <Box sx={{ display: 'flex', pl: 0, pt: 0.5, mx: 'auto' }}>
                <LogoSection />
            </Box>
        </Box>
        <Box sx={{ flexGrow: 1 }} />
        <Box sx={{ flexGrow: 1 }} />

        <ProfileSection />

        <Box sx={{ display: { xs: 'block', sm: 'none' } }}>
            <CustomBottomNavigation />
        </Box>
    </>
);

export default Header;
