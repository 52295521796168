import React from 'react';
import { useTheme } from '@mui/material/styles';
import { Box, Button, Divider, Grid, List, ListItem, ListItemIcon, ListItemText, Typography } from '@mui/material';
import { gridSpacing } from 'store/constant';
import MainCard from 'ui-component/cards/MainCard';
import CheckTwoToneIcon from '@mui/icons-material/CheckTwoTone';
import TwoWheelerTwoToneIcon from '@mui/icons-material/TwoWheelerTwoTone';
import AirportShuttleTwoToneIcon from '@mui/icons-material/AirportShuttleTwoTone';
import DirectionsBoatTwoToneIcon from '@mui/icons-material/DirectionsBoatTwoTone';
import { useNavigate } from 'react-router-dom';
import useAuth from 'hooks/useAuth';
import Container from 'component/Container';

const plans = [
    // {
    //     id: 1,
    //     active: false,
    //     icon: <TwoWheelerTwoToneIcon fontSize="large" color="inherit" />,
    //     title: 'Customer Temp',
    //     description:
    //         'Create one end product for a client, transfer that end product to your client, charge them for your services. The license is then transferred to the client.',
    //     price: 59,
    //     permission: [0, 1]
    // },
    {
        id: 2,
        active: false,
        icon: <AirportShuttleTwoToneIcon fontSize="large" />,
        title: 'Connector Plus',
        description:
            'This plan is suitable for Connectors who works in Finance and Real Estate Business. for managing client follow-ups and communication in real-time and better management. Find right client for your deals.',
        price: 499,
        permission: [0, 1, 2, 3, 4, 5, 6, 7]
    },
    {
        id: 3,
        active: false,
        icon: <DirectionsBoatTwoToneIcon fontSize="large" />,
        title: 'DSA',
        description:
            'This plan is for DSA who is working with multiple Banks, NBFCs & Lending Agencies, easily manage your client data for real-time communication and product management. so you can focus more on closing cases rather than manually updating concerned parties.',
        price: 5500,
        permission: [0, 1, 2, 3, 4, 5, 6, 7]
    }
];

const planList = [
    'One End Product',
    'No attribution required',
    'TypeScript',
    'Figma Design Resources',
    'Create Multiple Products',
    'Create a SaaS Project',
    'Resale Product',
    'Separate sale of our UI Elements?'
];

const connectorPlanList = [
    'For Single Member',
    'Client Management ( inc. Files )',
    'Easily Follow-up record management',
    'Easy Data Share with Financial Organization',
    'Work from anywhere',
    'Real Estate Management',
    'Direct - WhatsApp, Call, Email & Message'
];

const dsaPlanList = [
    'Dashboard for summarized cases',
    'Manage your all connectors centrally',
    'No Manual Reporting',
    'Real-time communication with teams',
    'Real-time updates to clients',
    'Real-time monitoring of TeleCallers',
    'Easy Data-Exchange between teams'
];

const Pricing = () => {
    const { isLoggedIn } = useAuth();
    const navigate = useNavigate();
    const theme = useTheme();
    const priceListDisable = {
        opacity: '0.4',
        '& >div> svg': {
            fill: theme.palette.secondary.light
        }
    };

    const handlePurchage = (planType) => {
        if (!isLoggedIn) {
            navigate('/register', { replace: true });
        } else {
            navigate('/checkout', { replace: true, state: { selectedProduct: planType } });
        }

        console.log('handlePurchage', planType);
    };

    const handleDemoRequest = (planType) => {
        if (!isLoggedIn) {
            navigate('/register', { replace: true });
        } else {
            navigate('/demo-request', { replace: true, state: { selectedProduct: planType } });
        }

        console.log('handlePurchage', planType);
    };

    return (
        <>
            <Grid container spacing={1.5} sx={{ px: { sm: 10 }, justifyContent: 'center' }}>
                <Grid item xs={12}>
                    <Box
                        sx={{
                            position: 'relative',
                            // backgroundColor: theme.palette.secondary.light
                            // backgroundImage: `linear-gradient(180deg, ${theme.palette.background.paper} 0%, ${theme.palette.secondary.light} 100%)`
                            // background: 'rgb(255,255,255)',
                            // backgroundImage:
                            //     'linear-gradient(180deg, rgba(131,58,180,0) 0%, rgba(255,255,255,1) 50%, rgba(252,176,69,0) 100%)'
                            background: 'radial-gradient(circle, rgba(255,255,255,1) 35%, rgba(206,229,218,0) 90%)'
                        }}
                    >
                        <Container position="relative" zIndex={3}>
                            <Box>
                                <Box marginBottom={3}>
                                    <Typography variant="h2" gutterBottom align="center" sx={{ fontWeight: 700, fontSize: '2.5em' }}>
                                        Flexible pricing options
                                    </Typography>
                                    <Typography
                                        variant="h4"
                                        component="p"
                                        color="text.secondary"
                                        align="center"
                                        sx={{ fontSize: '1.25em' }}
                                    >
                                        Our system simplifies lead management, saving you valuable time while improving conversion rates.
                                        <br />
                                        Experience heightened efficiency in converting prospects into customers and nurturing relationships
                                        throughout the sales cycle.
                                    </Typography>
                                </Box>
                            </Box>
                        </Container>
                    </Box>
                </Grid>
                {plans.map((plan, index) => {
                    const darkBorder = theme.palette.mode === 'dark' ? theme.palette.background.default : theme.palette.primary[200] + 75;
                    return (
                        <Grid item xs={12} sm={6} md={4} key={index}>
                            <MainCard
                                boxShadow
                                sx={{
                                    pt: 1.75,
                                    border: plan.active ? '2px solid' : '1px solid',
                                    borderColor: plan.active ? 'secondary.main' : darkBorder
                                }}
                            >
                                <Grid container textAlign="center" spacing={gridSpacing}>
                                    <Grid item xs={12}>
                                        <Box
                                            sx={{
                                                display: 'inline-flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                borderRadius: '50%',
                                                width: 80,
                                                height: 80,
                                                background:
                                                    theme.palette.mode === 'dark' ? theme.palette.dark[800] : theme.palette.primary.light,
                                                color: theme.palette.primary.main,
                                                '& > svg': {
                                                    width: 35,
                                                    height: 35
                                                }
                                            }}
                                        >
                                            {plan.icon}
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography
                                            variant="h6"
                                            sx={{
                                                fontSize: '1.5625rem',
                                                fontWeight: 500,
                                                position: 'relative',
                                                mb: 1.875,
                                                '&:after': {
                                                    content: '""',
                                                    position: 'absolute',
                                                    bottom: -15,
                                                    left: 'calc(50% - 25px)',
                                                    width: 50,
                                                    height: 4,
                                                    background: theme.palette.primary.main,
                                                    borderRadius: '3px'
                                                }
                                            }}
                                        >
                                            {plan.title}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography variant="body2">{plan.description}</Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography
                                            component="div"
                                            variant="body2"
                                            sx={{
                                                fontSize: '2.1875rem',
                                                fontWeight: 700,
                                                '& > span': {
                                                    fontSize: '1.25rem',
                                                    fontWeight: 500
                                                }
                                            }}
                                        >
                                            <sup>₹</sup>
                                            {plan.price}
                                            <span>/Year</span>
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <List
                                            sx={{
                                                m: 0,
                                                p: 0,
                                                '&> li': {
                                                    px: 0,
                                                    py: 0.625,
                                                    '& svg': {
                                                        fill: theme.palette.success.dark
                                                    }
                                                }
                                            }}
                                            component="ul"
                                        >
                                            <>
                                                {plan.id === 1 &&
                                                    planList.map((list, i) => (
                                                        <React.Fragment key={i}>
                                                            <ListItem sx={!plan.permission.includes(i) ? priceListDisable : {}}>
                                                                <ListItemIcon>
                                                                    <CheckTwoToneIcon sx={{ fontSize: '1.3rem' }} />
                                                                </ListItemIcon>
                                                                <ListItemText primary={list} />
                                                            </ListItem>
                                                            <Divider />
                                                        </React.Fragment>
                                                    ))}
                                                {plan.id === 2 &&
                                                    connectorPlanList.map((list, i) => (
                                                        <React.Fragment key={i}>
                                                            <ListItem sx={!plan.permission.includes(i) ? priceListDisable : {}}>
                                                                <ListItemIcon>
                                                                    <CheckTwoToneIcon sx={{ fontSize: '1.3rem' }} />
                                                                </ListItemIcon>
                                                                <ListItemText primary={list} />
                                                            </ListItem>
                                                            <Divider />
                                                        </React.Fragment>
                                                    ))}

                                                {plan.id === 3 &&
                                                    dsaPlanList.map((list, i) => (
                                                        <React.Fragment key={i}>
                                                            <ListItem sx={!plan.permission.includes(i) ? priceListDisable : {}}>
                                                                <ListItemIcon>
                                                                    <CheckTwoToneIcon sx={{ fontSize: '1.3rem' }} />
                                                                </ListItemIcon>
                                                                <ListItemText primary={list} />
                                                            </ListItem>
                                                            <Divider />
                                                        </React.Fragment>
                                                    ))}
                                            </>
                                        </List>
                                    </Grid>
                                    <Grid item xs={12}>
                                        {plan.id === 3 ? (
                                            <Button variant="outlined" onClick={() => handleDemoRequest(plan.id)} sx={{ mr: 1 }}>
                                                Request For Demo
                                            </Button>
                                        ) : null}
                                        <Button variant="outlined" onClick={() => handlePurchage(plan.id)}>
                                            Buy Now
                                        </Button>
                                    </Grid>
                                </Grid>
                            </MainCard>
                        </Grid>
                    );
                })}
            </Grid>
        </>
    );
};

export default Pricing;
