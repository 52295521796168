/* eslint-disable react/button-has-type */
// import { CachePersistor, LocalStorageWrapper } from 'apollo3-cache-persist';
// import { useCallback, useEffect, useState } from 'react';
import { ApolloClient, ApolloLink, ApolloProvider, from, HttpLink, Observable, useMutation } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { Alert, Box, Button, Stack } from '@mui/material';
// import { every, includes } from 'lodash';
import { useEffect, useState } from 'react';
import { getRoleName } from 'utils/common';
import { cache } from './cache';
import firebase from 'firebase/compat/app';
import useAuth from 'hooks/useAuth';
import NavigationScroll from 'layout/NavigationScroll';
import Routes from 'routes';
import ThemeCustomization from 'themes';
import Snackbar from 'ui-component/extended/Snackbar';
import Loader from 'ui-component/Loader';
import Locales from 'ui-component/Locales';
import RTLLayout from 'ui-component/RTLLayout';
// import Landing from 'views/landing';
import { every, includes } from 'lodash';
// import { getAuth, onIdTokenChanged } from 'firebase/auth';

const App = () => {
    const { defaultRole, user, logout, idToken, handleIdToken, isLoggedIn } = useAuth();
    const [showUpdateNotification, setShowUpdateNotification] = useState(false);

    // console.log({
    //     [process.env.REACT_APP_R]: every(['Telecaller', 'DSA'], (value) => includes(user.userRoles, value))
    //         ? getRoleName('dsa')
    //         : getRoleName(defaultRole),
    //     [process.env.REACT_APP_UI]: user?.idOriginal,
    //     Authorization: `Bearer ${idToken}`
    // });

    const getheader = (headers, newToken) => {
        if (!isLoggedIn) {
            return {
                ...headers,
                'content-type': 'application/json',
                [process.env.REACT_APP_R]: 'anonymous'
            };
        }
        return {
            ...headers,
            'content-type': 'application/json',
            [process.env.REACT_APP_R]: every(['Telecaller', 'DSA'], (value) => includes(user.userRoles, value))
                ? getRoleName('dsa')
                : getRoleName(defaultRole),
            [process.env.REACT_APP_UI]: user?.idOriginal,
            Authorization: `Bearer ${newToken || idToken}`
        };
    };

    useEffect(() => {
        const handleServiceWorkerUpdate = (event) => {
            setShowUpdateNotification(true);
        };

        navigator.serviceWorker.addEventListener('controllerchange', handleServiceWorkerUpdate);

        // Check if there is a waiting service worker
        if (navigator.serviceWorker.controller && navigator.serviceWorker.controller.state === 'activated') {
            navigator.serviceWorker.getRegistration().then((registration) => {
                if (registration && registration.waiting) {
                    setShowUpdateNotification(true);
                }
            });
        }

        return () => {
            navigator.serviceWorker.removeEventListener('controllerchange', handleServiceWorkerUpdate);
        };
    }, []);

    const handleUpdateClick = () => {
        if (navigator.serviceWorker.controller && navigator.serviceWorker.controller.state === 'activated') {
            navigator.serviceWorker.getRegistration().then((registration) => {
                if (registration && registration.waiting) {
                    registration.waiting.postMessage({ type: 'SKIP_WAITING' });
                    registration.waiting.addEventListener('statechange', (e) => {
                        if (e.target.state === 'activated') {
                            window.location.reload();
                        }
                    });
                }
            });
        }
    };

    const httpLink = new HttpLink({ uri: process.env.REACT_APP_URI });

    const handleAuth = new ApolloLink((operation, forward) => {
        operation.setContext(({ headers = {} }) => ({
            // headers: {
            //     ...headers,
            //     'content-type': 'application/json',
            //     [process.env.REACT_APP_R]: getRoleName(defaultRole),
            //     [process.env.REACT_APP_UI]: user?.idOriginal,
            //     Authorization: `Bearer ${idToken}`
            // }
            headers: getheader(headers)
        }));

        return forward(operation);
    });

    // 'invalid-jwt' || "jwt-invalid-claims"
    // eslint-disable-next-line consistent-return
    // const handleRefreshAndLogout = onError(({ graphQLErrors, networkError, operation, forward }) => {
    //     if (networkError?.statusCode === 401) {
    //         logout();
    //     } else if (includes(graphQLErrors[0]?.extensions.code, 'jwt')) {
    //         if (defaultRole && idToken && user) {
    //             return new Promise((resolve, reject) => {
    //                 firebase
    //                     .auth()
    //                     .currentUser.getIdToken(true)
    //                     .then(async (token) => {
    //                         handleIdToken(token);
    //                         // eslint-disable-next-line no-new
    //                         // await new ApolloLink((operation, forward) => {
    //                         // const newHeader = getheader({}, token);
    //                         // operation.setContext(({ headers = {} }) => ({
    //                         //     // headers: {
    //                         //     //     ...headers,
    //                         //     //     'content-type': 'application/json',
    //                         //     //     [process.env.REACT_APP_R]: getRoleName(defaultRole),
    //                         //     //     [process.env.REACT_APP_UI]: user?.idOriginal,
    //                         //     //     Authorization: `Bearer ${token}`
    //                         //     // }
    //                         //     headers: newHeader
    //                         // }));

    //                         const oldHeader = operation.getContext().headers;
    //                         operation.setContext({
    //                             headers: { ...oldHeader, Authorization: `Bearer ${token}` }
    //                         });

    //                         resolve(forward(operation));
    //                         // .then((response) => {
    //                         //     // console.log('Reauthenticattion Success', response);
    //                         //     window.location.reload();
    //                         // })
    //                         // .catch((error) => {
    //                         //     // console.log('Reauthenticattion Error', error);
    //                         // });
    //                         // });
    //                     })
    //                     .catch((error) => {
    //                         reject(console.log(error));
    //                     });
    //             });
    //             // eslint-disable-next-line no-else-return
    //         } else {
    //             window.location.reload();
    //         }
    //     } else if (graphQLErrors[0]?.extensions.code === 'invalid-headers') {
    //         window.location.reload();
    //     } else {
    //         console.error('GQL Error :', graphQLErrors[0].message);
    //     }
    // });

    // useEffect(() => {
    //     async function init() {
    //         const newPersistor = new CachePersistor({
    //             cache,
    //             storage: new LocalStorageWrapper(window.localStorage),
    //             debug: true,
    //             trigger: 'write'
    //         });
    //         await newPersistor.restore();
    //         setPersistor(newPersistor);
    //         setClient(
    //             new ApolloClient({
    //                 link: from([handleAuth, handleRefreshAndLogout, httpLink]),
    //                 cache
    //             })
    //         );
    //     }

    //     init();
    // }, []);

    // const clearCache = useCallback(() => {
    //     if (!persistor) {
    //         return;
    //     }
    //     persistor.purge();
    // }, [persistor]);

    // const reload = useCallback(() => {
    //     window.location.reload();
    // }, []);

    // eslint-disable-next-line consistent-return
    const errorLink = onError(({ graphQLErrors, networkError, operation, forward }) => {
        if (networkError?.statusCode === 401) {
            logout();
        } else if (includes(graphQLErrors[0]?.extensions.code, 'jwt')) {
            return new Observable((observer) => {
                firebase
                    .auth()
                    .currentUser.getIdToken(true)
                    .then(async (token) => {
                        operation.setContext(({ headers = {} }) => ({
                            headers: {
                                ...headers,
                                authorization: `Bearer ${token}` || null
                            }
                        }));
                    })
                    .then(() => {
                        const subscriber = {
                            next: observer.next.bind(observer),
                            error: observer.error.bind(observer),
                            complete: observer.complete.bind(observer)
                        };
                        forward(operation).subscribe(subscriber);
                    })
                    .catch((error) => {
                        observer.error(error);
                    });
            });
        } else if (graphQLErrors[0]?.extensions.code === 'invalid-headers') {
            window.location.reload();
        } else {
            console.error('GQL Error :', graphQLErrors[0].message);
        }
    });

    const createApolloClient = () => {
        const client = new ApolloClient({
            link: from([errorLink, handleAuth, httpLink]),
            cache
        });
        return client;
    };

    const client = createApolloClient();

    if (!client) {
        return <Loader />;
    }

    return (
        <>
            <ApolloProvider client={client}>
                <ThemeCustomization>
                    {showUpdateNotification && (
                        <Alert
                            action={
                                <Stack direction="row">
                                    <Button
                                        variant="outlined"
                                        sx={{ p: 0.3, mb: '4px', textTransform: 'capitalize' }}
                                        color="inherit"
                                        size="small"
                                        onClick={() => handleUpdateClick()}
                                    >
                                        Update
                                    </Button>
                                    <Button
                                        variant="outlined"
                                        sx={{ p: 0.3, mb: '4px', textTransform: 'capitalize', ml: 2 }}
                                        color="inherit"
                                        size="small"
                                        onClick={() => setShowUpdateNotification(false)}
                                    >
                                        Dismiss
                                    </Button>
                                </Stack>
                            }
                            sx={{
                                ml: isLoggedIn ? { xs: 0, sm: 26 } : 0,
                                mt: isLoggedIn ? { xs: 6.75, sm: 0 } : 0,
                                borderRadius: 0,
                                backgroundColor: '#2e7d32',
                                color: 'white'
                            }}
                            variant="filled"
                        >
                            New version available.
                        </Alert>
                    )}

                    <Box sx={{ mt: showUpdateNotification && isLoggedIn ? { xs: -6.75, sm: 0 } : 0 }}>
                        <RTLLayout>
                            <Locales>
                                <NavigationScroll>
                                    <>
                                        <Routes />
                                        <Snackbar />
                                    </>
                                </NavigationScroll>
                            </Locales>
                        </RTLLayout>
                        {/* <Landing /> */}
                    </Box>
                </ThemeCustomization>
            </ApolloProvider>
        </>
    );
};

export default App;
