import {
    AccountBalanceWallet,
    AdminPanelSettings,
    AssignmentTurnedIn,
    Badge,
    Cancel,
    CurrencyRupee,
    Home,
    Inventory2,
    Payment,
    PendingActions,
    Pin,
    Source,
    Tty
} from '@mui/icons-material';
import { FormattedMessage } from 'react-intl';

const DSA = {
    id: 'dsa',
    type: 'group',
    children: [
        {
            id: 'home',
            title: <FormattedMessage id="home" />,
            type: 'item',
            url: '/home',
            icon: Home,
            breadcrumbs: false
        },
        {
            id: 'leads',
            title: <FormattedMessage id="leads" />,
            type: 'item',
            url: '/leads',
            icon: Pin,
            breadcrumbs: false
        },
        {
            id: 'all-cases',
            title: <FormattedMessage id="all-cases" />,
            type: 'item',
            url: '/all-cases',
            icon: Source,
            breadcrumbs: false
        },
        {
            id: 'inprocess',
            title: <FormattedMessage id="inprocess" />,
            type: 'item',
            url: '/inprocess',
            icon: PendingActions,
            breadcrumbs: false
        },
        {
            id: 'approved',
            title: <FormattedMessage id="approved" />,
            type: 'item',
            url: '/approved',
            icon: AssignmentTurnedIn,
            breadcrumbs: false
        },
        {
            id: 'rejected',
            title: <FormattedMessage id="rejected" />,
            type: 'item',
            url: '/rejected',
            icon: Cancel,
            breadcrumbs: false
        },
        {
            id: 'disbursed',
            title: <FormattedMessage id="disbursed" />,
            type: 'item',
            url: '/disbursed',
            icon: Payment,
            breadcrumbs: false
        },
        {
            id: 'telecalling',
            title: <FormattedMessage id="telecalling" />,
            type: 'item',
            url: '/telecalling',
            icon: Tty,
            breadcrumbs: false
        },
        {
            id: 'employees',
            title: <FormattedMessage id="employees" />,
            type: 'item',
            url: '/employees',
            icon: Badge,
            breadcrumbs: false
        },
        {
            id: 'credits',
            title: <FormattedMessage id="credits" />,
            type: 'item',
            url: '/credits',
            icon: AccountBalanceWallet,
            breadcrumbs: false
        },
        {
            id: 'payouts',
            title: <FormattedMessage id="payouts" />,
            type: 'item',
            url: '/payouts',
            icon: CurrencyRupee,
            breadcrumbs: false
        },
        {
            id: 'products',
            title: <FormattedMessage id="commision" />,
            type: 'item',
            url: '/products',
            icon: Inventory2,
            breadcrumbs: false
        },
        {
            id: 'permission',
            title: <FormattedMessage id="permission" />,
            type: 'item',
            url: '/permission',
            icon: AdminPanelSettings,
            breadcrumbs: false
        }
    ]
};

export default DSA;
