/* eslint-disable import/prefer-default-export */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    accountantDashInfo: {},
    classRoomInfo: {},
    essentialFeeList: []
};

export const accountantSlice = createSlice({
    name: 'accountant',
    initialState,
    reducers: {
        setAccountantDashInfo: (state, action) => {
            state.accountantDashInfo = action.payload;
        },
        setClassRoomInfo: (state, action) => {
            state.classRoomInfo = action.payload;
        },
        setEssentialFeeList: (state, action) => {
            state.essentialFeeList = action.payload;
        }
    }
});

export const { setAccountantDashInfo, setClassRoomInfo, setEssentialFeeList } = accountantSlice.actions;

export default accountantSlice.reducer;
