// material-ui
// import { Backdrop, CircularProgress, Fade } from '@mui/material';
import LinearProgress from '@mui/material/LinearProgress';
import { styled } from '@mui/material/styles';
// import loadingAnimation from 'assets/lotties/loading.json';
// import Lottie from 'react-lottie';
// import PerfectScrollbar from 'react-perfect-scrollbar';

// const lottieLoadingOptions = {
//     loop: true,
//     autoplay: true,
//     animationData: loadingAnimation
// };

// styles
const LoaderWrapper = styled('div')({
    position: 'fixed',
    top: 0,
    left: 0,
    zIndex: 1301,
    width: '100%'
});

// ==============================|| LOADER ||============================== //

const Loader = () => (
    <LoaderWrapper>
        <LinearProgress color="primary" />

        {/* <Backdrop open={true} sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}>
            <Lottie height={200} width={200} options={lottieLoadingOptions} />
        </Backdrop> */}
    </LoaderWrapper>
);

export default Loader;
