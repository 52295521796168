/* eslint-disable import/prefer-default-export */
import { createSlice } from '@reduxjs/toolkit';
import { getCurrentSession } from 'utils/common';

const initialState = {
    newClass: {},
    newTeacher: {},
    newStaff: {},
    newStudentDetails: {},
    newStudentFDetails: {},
    newStudentMDetails: {},
    newStudentGDetails: {},
    newStudentClassDetails: {},
    recordClassesList: [],
    recordTeachersList: [],
    recordStaffsList: [],
    recordStudentsList: [],
    teacherDialogDetails: {},
    teacherId: '',
    staffDialogDetails: {},
    staffId: '',
    securityGaurdMailId: '',
    securityGaurdCanAccess: false,
    feesClassCMData: {},
    studentClassInfo: {},
    classId: '',
    className: '',
    classSection: '',
    classStartDate: '',
    subjectsList: [],
    attenClassDetails: {},
    selectedSession: getCurrentSession(),

    // Result
    resultCListSltTab: 0,
    resultSltClassDet: {},
    resultSltCRoomDet: {},
    resultSltSubjectDet: {},
    resultSltStudentDet: {},
    resultSltExamDet: {},

    // Classes
    sltClassDet: {},
    classesSltCRooomDet: {}
};

export const managmentSlice = createSlice({
    name: 'managment',
    initialState,
    reducers: {
        setNewClass: (state, action) => {
            state.newClass = action.payload;
        },
        setNewTeacher: (state, action) => {
            state.newTeacher = action.payload;
        },
        setNewStaff: (state, action) => {
            state.newStaff = action.payload;
        },
        setNewStudentDetails: (state, action) => {
            state.newStudentDetails = action.payload;
        },
        setNewStudentFDetails: (state, action) => {
            state.newStudentFDetails = action.payload;
        },
        setNewStudentMDetails: (state, action) => {
            state.newStudentMDetails = action.payload;
        },
        setNewStudentGDetails: (state, action) => {
            state.newStudentGDetails = action.payload;
        },
        setNewStudentClassDetails: (state, action) => {
            state.newStudentClassDetails = action.payload;
        },
        setRecordClassesList: (state, action) => {
            state.recordClassesList = action.payload;
        },
        setRecordTeachersList: (state, action) => {
            state.recordTeachersList = action.payload;
        },
        setRecordStaffsList: (state, action) => {
            state.recordStaffsList = action.payload;
        },
        setRecordStudentsList: (state, action) => {
            state.recordStudentsList = action.payload;
        },
        setTeacherDialogDetails: (state, action) => {
            state.teacherDialogDetails = action.payload;
        },
        setTeacherId: (state, action) => {
            state.teacherId = action.payload;
        },
        setStaffDialogDetails: (state, action) => {
            state.staffDialogDetails = action.payload;
        },
        setStaffId: (state, action) => {
            state.staffId = action.payload;
        },
        setSecurityGaurdMailId: (state, action) => {
            state.securityGaurdMailId = action.payload;
        },
        setSecurityGaurdCanAccess: (state, action) => {
            state.securityGaurdCanAccess = action.payload;
        },
        setFeesClassCMData: (state, action) => {
            state.feesClassCMData = action.payload;
        },
        setStudentClassInfo: (state, action) => {
            state.studentClassInfo = action.payload;
        },
        setClassId: (state, action) => {
            state.classId = action.payload;
        },
        setClassName: (state, action) => {
            state.className = action.payload;
        },
        setClassSection: (state, action) => {
            state.classSection = action.payload;
        },
        setClassStartDate: (state, action) => {
            state.classStartDate = action.payload;
        },
        setSubjectsList: (state, action) => {
            state.subjectsList = action.payload;
        },
        setAttenClassDetails: (state, action) => {
            state.attenClassDetails = action.payload;
        },
        setResultCListSltTab: (state, action) => {
            state.resultCListSltTab = action.payload;
        },
        setResultSltClassDet: (state, action) => {
            state.resultSltClassDet = action.payload;
        },
        setResultSltCRoomDet: (state, action) => {
            state.resultSltCRoomDet = action.payload;
        },
        setResultSltSubjectDet: (state, action) => {
            state.resultSltSubjectDet = action.payload;
        },
        setResultSltStudentDet: (state, action) => {
            state.resultSltStudentDet = action.payload;
        },
        setResultSltExamDet: (state, action) => {
            state.resultSltExamDet = action.payload;
        },
        setSelectedSession: (state, action) => {
            state.selectedSession = action.payload;
        },
        setClassesSltCRooomDet: (state, action) => {
            state.classesSltCRooomDet = action.payload;
        },
        setSltClassDet: (state, action) => {
            state.sltClassDet = action.payload;
        }
    }
});

export const {
    setNewClass,
    setNewTeacher,
    setNewStaff,
    setNewStudentDetails,
    setNewStudentFDetails,
    setNewStudentMDetails,
    setNewStudentGDetails,
    setNewStudentClassDetails,
    setRecordClassesList,
    setRecordTeachersList,
    setRecordStaffsList,
    setRecordStudentsList,
    setTeacherDialogDetails,
    setTeacherId,
    setStaffDialogDetails,
    setStaffId,
    setSecurityGaurdMailId,
    setSecurityGaurdCanAccess,
    setFeesClassCMData,
    setStudentClassInfo,
    setClassId,
    setClassName,
    setClassSection,
    setSubjectsList,
    setClassStartDate,
    setAttenClassDetails,
    setResultCListSltTab,
    setResultSltClassDet,
    setResultSltCRoomDet,
    setResultSltSubjectDet,
    setResultSltStudentDet,
    setResultSltExamDet,
    setSelectedSession,
    setClassesSltCRooomDet,
    setSltClassDet
} = managmentSlice.actions;

export default managmentSlice.reducer;
