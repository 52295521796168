import { lazy } from 'react';
import AuthGuard from 'utils/route-guard/AuthGuard';
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';

const Account = Loadable(lazy(() => import('views/common/Account')));
const Dashboard = Loadable(lazy(() => import('views/admin/Dashboard')));
const DSAList = Loadable(lazy(() => import('views/admin/DSAList')));
const Editors = Loadable(lazy(() => import('views/admin/Editors')));
const RoleAssign = Loadable(lazy(() => import('views/admin/RoleAssign')));

const AdminRoutes = {
    path: '/',
    element: (
        <AuthGuard>
            <MainLayout />
        </AuthGuard>
    ),
    children: [
        {
            path: '/account',
            element: <Account />
        },
        {
            path: '/home',
            element: <Dashboard />
        },
        {
            path: '/dsa-list',
            element: <DSAList />
        },
        {
            path: '/editors',
            element: <Editors />
        },
        {
            path: '/role-assign',
            element: <RoleAssign />
        }
    ]
};

export default AdminRoutes;
