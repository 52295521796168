import { lazy } from 'react';
import GuestGuard from 'utils/route-guard/GuestGuard';
import MinimalLayout from 'layout/MinimalLayout';
import NavMotion from 'layout/NavMotion';
import Loadable from 'ui-component/Loadable';

const Account = Loadable(lazy(() => import('views/common/Account')));
const Profile = Loadable(lazy(() => import('views/common/Profile')));
const Landing = Loadable(lazy(() => import('views/landing')));
const AuthLogin = Loadable(lazy(() => import('views/authentication/authentication/Login')));
const AuthRegister = Loadable(lazy(() => import('views/authentication/authentication/Register')));
const AuthForgotPassword = Loadable(lazy(() => import('views/authentication/authentication/ForgotPassword')));
const TermAndCondition = Loadable(lazy(() => import('views/authentication/authentication/TermAndCondition')));
const PrivecyPolicies = Loadable(lazy(() => import('views/authentication/authentication/PrivecyPolicies')));
const Disclaimer = Loadable(lazy(() => import('views/authentication/authentication/Disclaimer')));
const PricingOld = Loadable(lazy(() => import('views/authentication/authentication/Pricing')));
const Pricing = Loadable(lazy(() => import('views/pricing')));
const ContactPage = Loadable(lazy(() => import('views/authentication/authentication/ContactUs')));
const RefundPolicies = Loadable(lazy(() => import('views/authentication/authentication/RefundPolicies')));
const AboutUs = Loadable(lazy(() => import('views/authentication/authentication/AboutUs')));
const Checkout = Loadable(lazy(() => import('views/checkout')));

const LoginRoutes = {
    path: '/',
    element: (
        // <NavMotion>
        <GuestGuard>
            <MinimalLayout />
        </GuestGuard>
        //  </NavMotion>
    ),
    children: [
        {
            path: '/login',
            element: <AuthLogin />
        },
        {
            path: '/pricing',
            element: <Pricing />
        },
        {
            path: '/register',
            element: <AuthRegister />
        },
        {
            path: '/forgot',
            element: <AuthForgotPassword />
        },
        {
            path: '/term-and-condition',
            element: <TermAndCondition />
        },
        {
            path: '/desclaimer',
            element: <Disclaimer />
        },
        {
            path: '/privacy-policies',
            element: <PrivecyPolicies />
        },
        {
            path: '/product-pricing-page',
            element: <PricingOld />
        },
        {
            path: '/contact-us',
            element: <ContactPage />
        },
        {
            path: '/refund-policies',
            element: <RefundPolicies />
        },
        {
            path: '/about-us',
            element: <AboutUs />
        }
    ]
};

export default LoginRoutes;
