import { AssignmentTurnedIn, Cancel, Home, LocalActivity, PendingActions, RssFeed } from '@mui/icons-material';
import { FormattedMessage } from 'react-intl';

const customer = {
    id: 'customer',
    type: 'group',
    children: [
        {
            id: 'home',
            title: <FormattedMessage id="home" />,
            type: 'item',
            url: '/home',
            icon: Home,
            breadcrumbs: false
        },
        {
            id: 'blog',
            title: <FormattedMessage id="blog" />,
            type: 'item',
            url: '/blog',
            icon: RssFeed,
            breadcrumbs: false
        },
        {
            id: 'inprocess',
            title: <FormattedMessage id="inprocess" />,
            type: 'item',
            url: '/inprocess',
            icon: PendingActions,
            breadcrumbs: false
        },
        {
            id: 'approved',
            title: <FormattedMessage id="approved" />,
            type: 'item',
            url: '/approved',
            icon: AssignmentTurnedIn,
            breadcrumbs: false
        },
        {
            id: 'rejected',
            title: <FormattedMessage id="rejected" />,
            type: 'item',
            url: '/rejected',
            icon: Cancel,
            breadcrumbs: false
        },
        {
            id: 'active',
            title: <FormattedMessage id="active" />,
            type: 'item',
            url: '/active',
            icon: LocalActivity,
            breadcrumbs: false
        }
    ]
};

export default customer;
