import {
    AssignmentTurnedIn,
    Call,
    Cancel,
    CurrencyRupee,
    Diversity3,
    Home,
    Inventory2,
    LibraryAdd,
    Payments,
    PendingActions,
    Source,
    Tty
} from '@mui/icons-material';
import { FormattedMessage } from 'react-intl';

const connector = {
    id: 'connector',
    type: 'group',
    children: [
        {
            id: 'home',
            title: <FormattedMessage id="home" />,
            type: 'item',
            url: '/home',
            icon: Home,
            breadcrumbs: false
        },
        {
            id: 'new-client',
            title: <FormattedMessage id="new-client" />,
            type: 'item',
            url: '/new-client',
            icon: LibraryAdd,
            breadcrumbs: false
        },
        {
            id: 'clients',
            title: <FormattedMessage id="clients" />,
            type: 'item',
            url: '/clients',
            icon: Diversity3,
            breadcrumbs: false
        },
        {
            id: 'follow-up',
            title: <FormattedMessage id="follow-up" />,
            type: 'item',
            url: '/follow-up',
            icon: Call,
            breadcrumbs: false
        },
        {
            id: 'direct-cases',
            title: <FormattedMessage id="direct-cases" />,
            type: 'item',
            url: '/direct-cases',
            icon: Source,
            breadcrumbs: false
        },
        {
            id: 'all-cases',
            title: <FormattedMessage id="all-cases" />,
            type: 'item',
            url: '/all-cases',
            icon: Source,
            breadcrumbs: false
        },
        {
            id: 'inprocess',
            title: <FormattedMessage id="inprocess" />,
            type: 'item',
            url: '/inprocess',
            icon: PendingActions,
            breadcrumbs: false
        },
        {
            id: 'approved',
            title: <FormattedMessage id="approved" />,
            type: 'item',
            url: '/approved',
            icon: AssignmentTurnedIn,
            breadcrumbs: false
        },
        {
            id: 'rejected',
            title: <FormattedMessage id="rejected" />,
            type: 'item',
            url: '/rejected',
            icon: Cancel,
            breadcrumbs: false
        },
        {
            id: 'disbursed',
            title: <FormattedMessage id="disbursed" />,
            type: 'item',
            url: '/disbursed',
            icon: Payments,
            breadcrumbs: false
        },
        {
            id: 'payouts',
            title: <FormattedMessage id="payouts" />,
            type: 'item',
            url: '/payouts',
            icon: CurrencyRupee,
            breadcrumbs: false
        },
        {
            id: 'product-rates',
            title: <FormattedMessage id="product-rates" />,
            type: 'item',
            url: '/product-rates',
            icon: Inventory2,
            breadcrumbs: false
        }
    ]
};

export default connector;
