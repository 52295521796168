import { lazy } from 'react';
import AuthGuard from 'utils/route-guard/AuthGuard';
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';

const Account = Loadable(lazy(() => import('views/common/Account')));
const Home = Loadable(lazy(() => import('views/customer/Dashboard')));
const Blog = Loadable(lazy(() => import('views/customer/Blog')));
const Status = Loadable(lazy(() => import('views/customer/InProcess')));
const Rejected = Loadable(lazy(() => import('views/customer/Rejected')));
const Approved = Loadable(lazy(() => import('views/customer/Approved')));
const Active = Loadable(lazy(() => import('views/customer/Active')));

const AgentRoutes = {
    path: '/',
    element: (
        <AuthGuard>
            <MainLayout />
        </AuthGuard>
    ),
    children: [
        {
            path: '/account',
            element: <Account />
        },
        {
            path: '/home',
            element: <Home />
        },
        {
            path: '/blog',
            element: <Blog />
        },
        {
            path: '/inprocess',
            element: <Status />
        },
        {
            path: '/rejected',
            element: <Rejected />
        },
        {
            path: '/approved',
            element: <Approved />
        },
        {
            path: '/active',
            element: <Active />
        }
    ]
};

export default AgentRoutes;
