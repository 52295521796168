import { lazy } from 'react';
import AuthGuard from 'utils/route-guard/AuthGuard';
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';

const Account = Loadable(lazy(() => import('views/common/Account')));
const Profile = Loadable(lazy(() => import('views/common/Profile')));
const Dashboard = Loadable(lazy(() => import('views/telecaller/Dashboard')));
const Calling = Loadable(lazy(() => import('views/telecaller/Calling')));
const FollowUp = Loadable(lazy(() => import('views/telecaller/FollowUp')));
const AllCases = Loadable(lazy(() => import('views/telecaller/AllCases')));
const InProcess = Loadable(lazy(() => import('views/telecaller/InProcess')));
const Approved = Loadable(lazy(() => import('views/telecaller/Approved')));
const Rejected = Loadable(lazy(() => import('views/telecaller/Rejected')));
const Disbursed = Loadable(lazy(() => import('views/telecaller/Disbursed')));
const Payouts = Loadable(lazy(() => import('views/telecaller/Payouts')));
const ProductRates = Loadable(lazy(() => import('views/telecaller/ProductRates')));

const TelecallerRoutes = {
    path: '/',
    element: (
        <AuthGuard>
            <MainLayout />
        </AuthGuard>
    ),
    children: [
        {
            path: '/account',
            element: <Account />
        },
        {
            path: '/profile',
            element: <Profile />
        },
        {
            path: '/home',
            element: <Dashboard />
        },
        {
            path: '/calling',
            element: <Calling />
        },
        {
            path: '/follow-up',
            element: <FollowUp />
        },
        {
            path: '/all-cases',
            element: <AllCases />
        },
        {
            path: '/inprocess',
            element: <InProcess />
        },
        {
            path: '/approved',
            element: <Approved />
        },
        {
            path: '/rejected',
            element: <Rejected />
        },
        {
            path: '/disbursed',
            element: <Disbursed />
        },
        {
            path: '/payouts',
            element: <Payouts />
        },
        {
            path: '/product-rates',
            element: <ProductRates />
        }
    ]
};

export default TelecallerRoutes;
