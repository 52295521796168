/* eslint-disable array-callback-return */
import { FIREBASE_API } from 'config';
import { getStorage } from 'firebase/storage';
import { capitalize, compact, isEmpty, toNumber } from 'lodash';
import { createContext, useEffect, useReducer, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { DEFAULT_ROLE, ID_TOKEN, LOGIN, LOGOUT } from 'store/actions';
import { persister } from '../store';
import PropTypes from 'prop-types';
import accountReducer from 'store/accountReducer';
import Loader from 'ui-component/Loader';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import { getPlanType } from 'utils/common';
import { format } from 'date-fns';

let storage;
if (!firebase.apps.length) {
    const app = firebase.initializeApp(FIREBASE_API);
    storage = getStorage(app);
}

const initialState = {
    isLoggedIn: false,
    isInitialized: false,
    user: null,
    defaultRole: 'anon',
    idToken: ''
};

const FirebaseContext = createContext(null);

const filterUserType = (roles) => {
    // eslint-disable-next-line prefer-const
    let role = 'free';
    // const userRoles = [
    //     'DSA',
    //     'Backoffice',
    //     'Televendor',
    //     'Dsaaccess',
    //     'Connector',
    //     'Demoaccess',
    //     'Telecaller',
    //     'Customer',
    //     'Editor',
    //     'Admin'
    // ];
    const userRoles = [];
    roles?.map((item) => {
        if (item === 'lsbadmin') {
            role = 'admin';
            userRoles.push(capitalize(role));
            localStorage.setItem('selctedRole', role);
        } else if (item === 'editor') {
            role = 'editor';
            userRoles.push(capitalize(role));
            localStorage.setItem('selctedRole', role);
        } else if (item?.uT === 'lsbap') {
            role = 'connector';
            userRoles.push(capitalize(role));
            localStorage.setItem('selctedRole', role);
        } else if (item?.uT === 'lsbmtf') {
            role = 'dsa';
            userRoles.push(capitalize(role));
            localStorage.setItem('selctedRole', role);
        } else if (item?.uT === 'lsbbof') {
            role = 'backoffice';
            userRoles.push(capitalize(role));
            localStorage.setItem('selctedRole', role);
        } else if (item?.uT === 'lsbsr') {
            role = 'telecaller';
            userRoles.push(capitalize(role));
            localStorage.setItem('selctedRole', role);
        }
    });
    return {
        role: localStorage.getItem('selctedRole') ? localStorage.getItem('selctedRole') : role,
        userRoles: !isEmpty(userRoles) ? [...userRoles] : ['Free']
    };
};

export const FirebaseProvider = ({ children }) => {
    const navigate = useNavigate();
    const [state, dispatch] = useReducer(accountReducer, initialState);
    const [isRecordUpdated, setRecordUpdated] = useState(false);

    const handleRole = (role, callerType = undefined, user = undefined, id = undefined, isDisabledRedirectToHome = false) => {
        // const selectedRole = role || user?.emailVerified ? 'free' : undefined;
        const selectedRole = role;

        if (callerType === 'dsa' && role === 'telecaller') {
            dispatch({
                type: LOGIN,
                payload: {
                    isLoggedIn: false,
                    user: {
                        ...user,
                        id,
                        userRoles: ['Telecaller', 'DSA'],
                        guid: user.id
                    }
                }
            });
        }

        if (callerType === 'telecaller' && role === 'dsa') {
            dispatch({
                type: LOGIN,
                payload: {
                    isLoggedIn: false,
                    user: {
                        ...user,
                        id: user.guid,
                        userRoles: ['DSA'],
                        guid: ''
                    }
                }
            });
        }

        localStorage.setItem('selctedRole', selectedRole);
        dispatch({
            type: DEFAULT_ROLE,
            payload: {
                role: selectedRole || 'anon'
            }
        });
        if (!isDisabledRedirectToHome) {
            if (selectedRole === 'free') {
                navigate('/', { replace: true });
            } else if (user?.emailVerified === false) {
                navigate('/verifyemail', { replace: true });
            } else {
                navigate('/home', { replace: true });
            }
        }
    };

    // const handleRole = (role, isDisabledRedirectToHome = false) => {
    //     const selectedRole = role;

    //     localStorage.setItem('selctedRole', selectedRole);
    //     dispatch({
    //         type: DEFAULT_ROLE,
    //         payload: {
    //             role: selectedRole || 'anon'
    //         }
    //     });
    //     if (!isDisabledRedirectToHome) {
    //         navigate('/home', { replace: true });
    //     }
    // };

    const handleIdToken = (idToken) => {
        dispatch({
            type: ID_TOKEN,
            payload: {
                idToken
            }
        });
    };

    const handleRefreshToken = () => {
        const currentUser = firebase.auth().currentUser;
        if (currentUser) {
            currentUser.getIdToken(true).then((idToken) => {
                const unsubscribe = firebase.auth().onIdTokenChanged((user) => {
                    if (user) {
                        user.getIdTokenResult().then((idTokenResult) => {
                            const roles = [
                                idTokenResult.claims.admin,
                                idTokenResult.claims.editor,
                                idTokenResult.claims.lsb,
                                idTokenResult.claims.dsa,
                                idTokenResult.claims.lsbbo,
                                idTokenResult.claims.lsbsr
                            ];
                            const userType = filterUserType(roles);
                            const compactedRoles = compact(roles);
                            dispatch({
                                type: LOGIN,
                                payload: {
                                    isLoggedIn: false,
                                    user: {
                                        id: user.uid,
                                        idOriginal: user.uid,
                                        email: user.email,
                                        name: user.displayName || 'Guest',
                                        isEmailVerfied: process.env.NODE_ENV === 'development' ? true : user.emailVerified,
                                        isEmailVerfiedO: user.emailVerified,
                                        userData: user,
                                        planName: getPlanType(compactedRoles[0]?.uT),
                                        userRoles: userType.userRoles,
                                        dueDate:
                                            !isEmpty(compactedRoles) && compactedRoles[0]?.dD
                                                ? format(toNumber(compactedRoles[0]?.dD), 'dd/MM/yyyy')
                                                : 'N/A',
                                        expirationTime: idTokenResult.claims.exp * 1000,
                                        guid: ''
                                    }
                                }
                            });
                            handleIdToken(idToken);
                            // handleRole(userType.role, true, user);
                            handleRole(userType.role, undefined, user, undefined, true);
                            persister.persist();
                            window.location.reload();
                        });
                    } else {
                        dispatch({
                            type: LOGOUT
                        });
                    }
                    return () => unsubscribe();
                });
            });
        }
    };

    useEffect(() => {
        const unsubscribe = firebase.auth().onAuthStateChanged(async (user) => {
            if (user) {
                const idToken = await user.getIdToken(true);
                user.getIdTokenResult().then((idTokenResult) => {
                    const roles = [
                        idTokenResult.claims.admin,
                        idTokenResult.claims.editor,
                        idTokenResult.claims.lsb,
                        idTokenResult.claims.dsa,
                        idTokenResult.claims.lsbbo,
                        idTokenResult.claims.lsbsr
                    ];
                    const userType = filterUserType(roles);
                    const compactedRoles = compact(roles);
                    dispatch({
                        type: LOGIN,
                        payload: {
                            isLoggedIn: false,
                            user: {
                                id: user.uid,
                                idOriginal: user.uid,
                                email: user.email,
                                name: user.displayName || 'Guest',
                                isEmailVerfied: process.env.NODE_ENV === 'development' ? true : user.emailVerified,
                                isEmailVerfiedO: user.emailVerified,
                                userData: user,
                                planName: getPlanType(compactedRoles[0]?.uT),
                                userRoles: userType.userRoles,
                                dueDate:
                                    !isEmpty(compactedRoles) && compactedRoles[0]?.dD
                                        ? format(toNumber(compactedRoles[0]?.dD), 'dd/MM/yyyy')
                                        : 'N/A',
                                expirationTime: idTokenResult.claims.exp * 1000,
                                guid: ''
                            }
                        }
                    });
                    handleIdToken(idToken);
                    // handleRole(userType.role, true, user);
                    handleRole(userType.role, undefined, user, undefined, true);
                    persister.persist();
                });
            } else {
                dispatch({
                    type: LOGOUT
                });
            }
        });

        return () => unsubscribe();
    }, [isRecordUpdated]);

    const firebaseEmailPasswordSignIn = (email, password) => firebase.auth().signInWithEmailAndPassword(email, password);

    const firebaseGoogleSignIn = () => {
        const provider = new firebase.auth.GoogleAuthProvider();

        return firebase.auth().signInWithPopup(provider);
    };

    const firebaseRegister = async (email, password) => firebase.auth().createUserWithEmailAndPassword(email, password);

    const logout = async () => {
        let isLogedOut;
        await firebase
            .auth()
            .signOut()
            .then(() => {
                isLogedOut = true;
            })
            .catch((error) => {
                isLogedOut = false;
            });

        return isLogedOut;
    };

    const resetPassword = async (email) => {
        await firebase.auth().sendPasswordResetEmail(email);
    };

    const updateProfile = () => {};
    if (state.isInitialized !== undefined && !state.isInitialized) {
        return <Loader />;
    }

    const updateDisplayName = async (displayName) => {
        await firebase
            .auth()
            .currentUser.updateProfile({
                displayName
            })
            .then(() => {
                setRecordUpdated(!isRecordUpdated);
                return true;
            })
            .catch(() => false);
    };

    const updatePhotoUrl = async (photoURL) => {
        await firebase
            .auth()
            .currentUser.updateProfile({
                photoURL
            })
            .then(() => {
                setRecordUpdated(!isRecordUpdated);
                return true;
            })
            .catch(() => false);
    };

    const updateEmail = async (email) => {
        await firebase
            .auth()
            .currentUser.updateEmail(email)
            .then(() => true)
            .catch(() => false);
    };

    const changePassword = async (newPwd) => {
        await firebase
            .auth()
            .currentUser.updatePassword(newPwd)
            .then((response) => {
                // console.log(response);
            })
            .catch((error) => {
                // console.log(error);
            });
    };

    const getInitStorage = () => {
        if (state.isInitialized) {
            return storage;
        }
        return null;
    };

    return (
        <FirebaseContext.Provider
            value={{
                ...state,
                firebaseRegister,
                firebaseEmailPasswordSignIn,
                login: () => {},
                firebaseGoogleSignIn,
                logout,
                resetPassword,
                updateProfile,
                handleRole,
                updateDisplayName,
                updateEmail,
                changePassword,
                getInitStorage,
                updatePhotoUrl,
                handleRefreshToken,
                handleIdToken
            }}
        >
            {children}
        </FirebaseContext.Provider>
    );
};

FirebaseProvider.propTypes = {
    children: PropTypes.node
};

export default FirebaseContext;
