/* eslint-disable no-nested-ternary */
import { lazy, useEffect, useMemo, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { AppBar, Box, Container, CssBaseline, Grid, IconButton, Toolbar, useMediaQuery } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import { useDispatch, useSelector } from 'store';
import { drawerWidth } from 'store/constant';
import { openDrawer } from 'store/slices/menu';
import { IconChevronRight, IconChevronsLeft, IconChevronsRight } from '@tabler/icons';
import { toString } from 'lodash';
import Breadcrumbs from 'ui-component/extended/Breadcrumbs';
import Header from './Header';
import Sidebar from './Sidebar';
import useConfig from 'hooks/useConfig';
import useAuth from 'hooks/useAuth';
import useMenuItems from 'hooks/useMenuItems';
import Loadable from 'ui-component/Loadable';

// const Pricing = Loadable(lazy(() => import('views/PBXCMP/common/Pricing')));

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open, defaultrole, issx }) => ({
    ...(issx === 'true' && theme.typography.mainContentSx),
    ...(issx === 'false' && theme.typography.mainContentSm),
    ...(!open && {
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.shorter
        }),
        [theme.breakpoints.up('md')]: {
            marginLeft: -(drawerWidth - 0),
            width: `calc(100% - ${drawerWidth}px)`
        },
        [theme.breakpoints.down('md')]: {
            width: `calc(100% - ${drawerWidth}px)`,
            padding: '20px'
        },
        [theme.breakpoints.down('sm')]: {
            marginLeft: '0px',
            width: `calc(100% - ${drawerWidth}px)`,
            padding: '8px',
            paddingLeft: '0px',
            paddingRight: '0px',
            marginRight: '0px'
        }
    }),
    ...(open && {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.shorter
        }),
        marginLeft: 0,
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        width: `calc(100% - ${drawerWidth}px)`,
        [theme.breakpoints.down('md')]: {
            marginLeft: '20px'
        },
        [theme.breakpoints.down('sm')]: {
            marginLeft: '10px'
        }
    })
}));

const MainLayout = () => {
    const { defaultRole, isLoggedIn, user } = useAuth();
    const { drawerOpen } = useSelector((state) => state.menu);
    const { container } = useConfig();
    const [open, setOpen] = useState(defaultRole === 'free' && isLoggedIn);
    const navigation = useMenuItems();
    const theme = useTheme();
    const matchDownMd = useMediaQuery(theme.breakpoints.down('lg'));
    const matchDownSM = useMediaQuery(theme.breakpoints.down('md'));
    const dispatch = useDispatch();

    const handleClose = () => {
        setOpen(!open);
    };

    useEffect(() => {
        dispatch(openDrawer(!matchDownMd));
    }, [dispatch, matchDownMd]);

    const header = useMemo(
        () => (
            <Toolbar>
                <Header />
            </Toolbar>
        ),
        []
    );

    return (
        <>
            <Box
                sx={{
                    display: { xs: 'block', sm: 'none' },
                    mb: '52px'
                }}
            >
                <CssBaseline />
                <AppBar
                    enableColorOnDark
                    position="fixed"
                    color="inherit"
                    elevation={0}
                    sx={{
                        bgcolor: theme.palette.background.default,
                        transition: drawerOpen ? theme.transitions.create('width') : 'none'
                    }}
                >
                    {header}
                </AppBar>

                <Main
                    theme={theme}
                    open={drawerOpen}
                    defaultrole={defaultRole}
                    issx={toString(matchDownSM)}
                    sx={{ width: 'calc(100% - 0px) !important' }}
                >
                    {container && (
                        <Container maxWidth="lg">
                            <Breadcrumbs separator={IconChevronRight} navigation={navigation} icon title rightAlign />
                            {matchDownSM && <Outlet />}
                        </Container>
                    )}
                    {!container && (
                        <>
                            <Breadcrumbs separator={IconChevronRight} navigation={navigation} icon title rightAlign />
                            {matchDownSM && <Outlet />}
                        </>
                    )}
                </Main>
            </Box>
            <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
                <Box
                    sx={{
                        display: 'flex',
                        mt: '0px'
                    }}
                >
                    <CssBaseline />

                    <Sidebar />

                    <Main theme={theme} open={drawerOpen} defaultrole={defaultRole} issx={toString(matchDownSM)} sx={{ mt: 0 }}>
                        {!container && (
                            <>
                                {drawerOpen ? (
                                    <Box
                                        sx={{
                                            display: {
                                                sm: 'flex',
                                                alignItems: 'center',
                                                position: 'absolute',
                                                bottom: '31px !important'
                                            }
                                        }}
                                    >
                                        <IconButton
                                            variant="rounded"
                                            sx={{
                                                ...theme.typography.commonAvatar,
                                                ...theme.typography.mediumAvatar,
                                                overflow: 'hidden',
                                                '&:hover': {
                                                    background: theme.palette.mode === 'dark' ? theme.palette.secondary.main : '#ceced5'
                                                },
                                                position: 'fixed',
                                                zIndex: 1200,
                                                width: 24,
                                                borderRadius: '0px',
                                                height: 62,
                                                p: 0,
                                                left: 187
                                            }}
                                            onClick={() => dispatch(openDrawer(!drawerOpen))}
                                            color="inherit"
                                        >
                                            <IconChevronsLeft stroke={1.5} size="24px" />
                                        </IconButton>
                                    </Box>
                                ) : null}

                                {!drawerOpen ? (
                                    <Box
                                        sx={{
                                            display: {
                                                sm: 'flex',
                                                alignItems: 'center',
                                                position: 'fixed',
                                                bottom: '0px !important',
                                                height: '100vh',
                                                width: 24,
                                                backgroundColor: '#ceced5',
                                                left: 0,
                                                cursor: 'pointer'
                                            }
                                        }}
                                        onClick={() => dispatch(openDrawer(!drawerOpen))}
                                    >
                                        <IconChevronsRight stroke={1.5} size="35px" />
                                    </Box>
                                ) : null}

                                {!matchDownSM && (
                                    <Grid ml={drawerOpen ? 0 : 3}>
                                        <Outlet />
                                    </Grid>
                                )}
                            </>
                        )}
                    </Main>
                    {/* {defaultRole === 'free' && user.isEmailVerfied && <Pricing open={open} handleClose={handleClose} />} */}
                </Box>
            </Box>
        </>
    );
};

export default MainLayout;
