import {
    AddBoxOutlined,
    InfoOutlined,
    PercentOutlined,
    RestorePageOutlined,
    TaskOutlined,
    Home as HomeIcon,
    Pin,
    Source,
    Badge,
    LibraryAdd,
    Tty,
    Call,
    RssFeed,
    PendingActions,
    LocalActivity,
    AccountBalance,
    Category,
    Inventory2,
    List,
    MenuBookOutlined,
    AssignmentInd
} from '@mui/icons-material';
import { Box, Grid, Typography, useTheme } from '@mui/material';
import { forwardRef, useEffect, useRef, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import AppsIcon from '@mui/icons-material/Apps';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import Paper from '@mui/material/Paper';
import MuiTooltip from '@mui/material/Tooltip';
import useAuth from 'hooks/useAuth';
import SelectedListItem from './SelectedListItem';
import './navigation.css';
import { FormattedMessage } from 'react-intl';

export default function Home() {
    const theme = useTheme();
    const { defaultRole } = useAuth();
    const ref = useRef(null);
    const location = useLocation();
    const [selectedMenu, setSelectedMenu] = useState();
    const [open, setOpen] = useState(false);

    useEffect(() => {
        if (location.pathname === '/home') {
            setSelectedMenu(0);
        }
    }, [location.pathname]);

    const handleTooltipOpen = () => {
        setOpen(!open);
    };

    const handleMenuSelect = (menuName) => {
        setSelectedMenu(menuName);
        if (menuName !== 2) setOpen(false);
    };

    const leftItem = [];
    const rightItem = [];

    if (defaultRole === 'anon' || defaultRole === 'free') {
        leftItem.push({ leftName: 'Home', leftLink: '/home' });
        leftItem.push({ leftName: 'About PBX', leftLink: '/aboutus' });
    }
    if (defaultRole === 'dsa') {
        leftItem.push({ leftName: <FormattedMessage id="home" />, leftLink: '/home', icon: <HomeIcon /> });
        leftItem.push({ leftName: <FormattedMessage id="leads" />, leftLink: '/leads', icon: <Pin /> });
        rightItem.push({ rightName: <FormattedMessage id="all-cases" />, rightLink: '/all-cases', icon: <Source /> });
        rightItem.push({ rightName: <FormattedMessage id="employees" />, rightLink: '/employees', icon: <Badge /> });
    }
    if (defaultRole === 'backoffice') {
        leftItem.push({ leftName: <FormattedMessage id="home" />, leftLink: '/home', icon: <HomeIcon /> });
        leftItem.push({ leftName: <FormattedMessage id="leads" />, leftLink: '/leads', icon: <Pin /> });
        rightItem.push({ rightName: <FormattedMessage id="all-cases" />, rightLink: '/all-cases', icon: <Source /> });
        rightItem.push({ rightName: <FormattedMessage id="employees" />, rightLink: '/employees', icon: <Badge /> });
    }
    if (defaultRole === 'televendor') {
        leftItem.push({ leftName: <FormattedMessage id="home" />, leftLink: '/home', icon: <HomeIcon /> });
        leftItem.push({ leftName: <FormattedMessage id="televendor-tab-1" />, leftLink: '/televendor-tab-1', icon: <Category /> });
        rightItem.push({ rightName: <FormattedMessage id="televendor-tab-2" />, rightLink: '/televendor-tab-2', icon: <Category /> });
        rightItem.push({ rightName: <FormattedMessage id="televendor-tab-3" />, rightLink: '/televendor-tab-3', icon: <Category /> });
    }
    if (defaultRole === 'dsaaccess') {
        leftItem.push({ leftName: <FormattedMessage id="home" />, leftLink: '/home', icon: <HomeIcon /> });
        leftItem.push({ leftName: <FormattedMessage id="dsaaccess-tab-1" />, leftLink: '/dsaaccess-tab-1', icon: <Category /> });
        rightItem.push({ rightName: <FormattedMessage id="dsaaccess-tab-2" />, rightLink: '/dsaaccess-tab-2', icon: <Category /> });
        rightItem.push({ rightName: <FormattedMessage id="dsaaccess-tab-3" />, rightLink: '/dsaaccess-tab-3', icon: <Category /> });
    }
    if (defaultRole === 'connector') {
        leftItem.push({ leftName: <FormattedMessage id="home" />, leftLink: '/home', icon: <HomeIcon /> });
        leftItem.push({ leftName: <FormattedMessage id="new-client" />, leftLink: '/new-client', icon: <LibraryAdd /> });
        rightItem.push({ rightName: <FormattedMessage id="follow-up" />, rightLink: '/follow-up', icon: <Call /> });
        rightItem.push({ rightName: <FormattedMessage id="all-cases" />, rightLink: '/all-cases', icon: <Source /> });
    }
    if (defaultRole === 'demoaccess') {
        leftItem.push({ leftName: <FormattedMessage id="home" />, leftLink: '/home', icon: <HomeIcon /> });
        leftItem.push({ leftName: <FormattedMessage id="leads" />, leftLink: '/leads', icon: <Pin /> });
        rightItem.push({ rightName: <FormattedMessage id="all-cases" />, rightLink: '/all-cases', icon: <Source /> });
        rightItem.push({ rightName: <FormattedMessage id="employees" />, rightLink: '/employees', icon: <Badge /> });
    }
    if (defaultRole === 'telecaller') {
        leftItem.push({ leftName: <FormattedMessage id="home" />, leftLink: '/home', icon: <HomeIcon /> });
        leftItem.push({ leftName: <FormattedMessage id="calling" />, leftLink: '/calling', icon: <Tty /> });
        rightItem.push({ rightName: <FormattedMessage id="follow-up" />, rightLink: '/follow-up', icon: <Call /> });
        rightItem.push({ rightName: <FormattedMessage id="all-cases" />, rightLink: '/all-cases', icon: <Source /> });
    }
    if (defaultRole === 'customer') {
        leftItem.push({ leftName: <FormattedMessage id="home" />, leftLink: '/home', icon: <HomeIcon /> });
        leftItem.push({ leftName: <FormattedMessage id="blog" />, leftLink: '/blog', icon: <RssFeed /> });
        rightItem.push({ rightName: <FormattedMessage id="inprocess" />, rightLink: '/inprocess', icon: <PendingActions /> });
        rightItem.push({ rightName: <FormattedMessage id="active" />, rightLink: '/active', icon: <LocalActivity /> });
    }
    if (defaultRole === 'editor') {
        leftItem.push({ leftName: <FormattedMessage id="home" />, leftLink: '/home', icon: <HomeIcon /> });
        leftItem.push({ leftName: <FormattedMessage id="bank-and-nbfc" />, leftLink: '/bank-and-nbfc', icon: <AccountBalance /> });
        rightItem.push({ rightName: <FormattedMessage id="product-category" />, rightLink: '/product-category', icon: <Category /> });
        rightItem.push({ rightName: <FormattedMessage id="products" />, rightLink: '/products', icon: <Inventory2 /> });
    }
    if (defaultRole === 'admin') {
        leftItem.push({ leftName: <FormattedMessage id="home" />, leftLink: '/home', icon: <HomeIcon /> });
        leftItem.push({ leftName: <FormattedMessage id="dsa-list" />, leftLink: '/dsa-list', icon: <List /> });
        rightItem.push({ rightName: <FormattedMessage id="editors" />, rightLink: '/editors', icon: <MenuBookOutlined /> });
        rightItem.push({ rightName: <FormattedMessage id="role-assign" />, rightLink: '/role-assign', icon: <AssignmentInd /> });
    }

    const itemTarget = '_self';
    const listItemProps = (link) => ({
        component: forwardRef((props, ref) => <Link ref={ref} {...props} to={link} target={itemTarget} />)
    });

    return (
        <Box ref={ref}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Paper
                        sx={{
                            position: 'fixed',
                            bottom: 0,
                            left: 0,
                            right: 0
                        }}
                    >
                        <BottomNavigation
                            showLabels
                            sx={{
                                backgroundColor: theme.palette.primary.main,
                                height: 52,
                                color: '#ffffff',
                                '& 	.MuiBottomNavigationAction-root': {
                                    minWidth: '20%',
                                    alignContent: 'center',
                                    display: 'flex',
                                    color: 'white',
                                    padding: 0
                                },
                                '& .Mui-selected': {
                                    bgcolor: theme.palette.dark[800],
                                    color: 'white !important'
                                }
                            }}
                            value={selectedMenu}
                            onChange={(e, newValue) => {
                                handleMenuSelect(newValue);
                            }}
                        >
                            <BottomNavigationAction
                                {...listItemProps(leftItem[0]?.leftLink)}
                                label={<Typography sx={{ fontSize: '0.70rem' }}>{leftItem[0]?.leftName}</Typography>}
                                icon={leftItem[0]?.icon}
                            />

                            <BottomNavigationAction
                                {...listItemProps(leftItem[1]?.leftLink)}
                                label={<Typography sx={{ fontSize: '0.70rem' }}>{leftItem[1]?.leftName}</Typography>}
                                icon={leftItem[1]?.icon}
                            />

                            {(defaultRole === 'dsa' ||
                                defaultRole === 'demoaccess' ||
                                defaultRole === 'connector' ||
                                defaultRole === 'backoffice' ||
                                defaultRole === 'telecaller' ||
                                defaultRole === 'customer' ||
                                defaultRole === 'dsaaccess' ||
                                defaultRole === 'televendor') && (
                                <MuiTooltip
                                    PopperProps={{
                                        disablePortal: true
                                    }}
                                    onClose={() => handleTooltipOpen()}
                                    open={open}
                                    disableFocusListener
                                    disableHoverListener
                                    disableTouchListener
                                    title={<SelectedListItem handleTooltipOpen={handleTooltipOpen} />}
                                    arrow
                                    componentsProps={{
                                        tooltip: {
                                            sx: {
                                                bgcolor: theme.palette.primary.main,
                                                '& .MuiTooltip-arrow': {
                                                    color: theme.palette.primary.main,
                                                    height: 70,
                                                    width: 99,
                                                    marginBottom: '-68px !important'
                                                },
                                                marginBottom: '12px !important',
                                                padding: '7px 7px',
                                                width: 150
                                            }
                                        }
                                    }}
                                >
                                    <BottomNavigationAction
                                        sx={{
                                            justifyContent: 'center',
                                            zIndex: 1501
                                        }}
                                        label={<Typography sx={{ fontSize: '0.70rem' }}>Menu</Typography>}
                                        icon={<AppsIcon />}
                                        onClick={() => handleTooltipOpen()}
                                    />
                                </MuiTooltip>
                            )}
                            {(defaultRole === 'dsa' ||
                                defaultRole === 'demoaccess' ||
                                defaultRole === 'connector' ||
                                defaultRole === 'backoffice' ||
                                defaultRole === 'telecaller' ||
                                defaultRole === 'customer' ||
                                defaultRole === 'dsaaccess' ||
                                defaultRole === 'televendor' ||
                                defaultRole === 'editor' ||
                                defaultRole === 'admin') && (
                                <BottomNavigationAction
                                    {...listItemProps(rightItem[0]?.rightLink)}
                                    label={<Typography sx={{ fontSize: '0.70rem' }}>{rightItem[0]?.rightName}</Typography>}
                                    icon={rightItem[0]?.icon}
                                />
                            )}
                            {(defaultRole === 'dsa' ||
                                defaultRole === 'demoaccess' ||
                                defaultRole === 'connector' ||
                                defaultRole === 'backoffice' ||
                                defaultRole === 'telecaller' ||
                                defaultRole === 'customer' ||
                                defaultRole === 'dsaaccess' ||
                                defaultRole === 'televendor' ||
                                defaultRole === 'editor' ||
                                defaultRole === 'admin') && (
                                <BottomNavigationAction
                                    {...listItemProps(rightItem[1]?.rightLink)}
                                    label={<Typography sx={{ fontSize: '0.70rem' }}>{rightItem[1]?.rightName}</Typography>}
                                    icon={rightItem[1]?.icon}
                                />
                            )}
                        </BottomNavigation>
                    </Paper>
                </Grid>
            </Grid>
        </Box>
    );
}
