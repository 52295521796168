import { lazy } from 'react';
import AuthGuard from 'utils/route-guard/AuthGuard';
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';

const Account = Loadable(lazy(() => import('views/common/Account')));
const Dashboard = Loadable(lazy(() => import('views/editor/Dashboard')));
const BankAndNBFC = Loadable(lazy(() => import('views/editor/BankAndNBFC')));
const ProductCategory = Loadable(lazy(() => import('views/editor/ProductCategory')));
const Products = Loadable(lazy(() => import('views/editor/Products')));

const EditorRoutes = {
    path: '/',
    element: (
        <AuthGuard>
            <MainLayout />
        </AuthGuard>
    ),
    children: [
        {
            path: '/account',
            element: <Account />
        },
        {
            path: '/home',
            element: <Dashboard />
        },
        {
            path: '/bank-and-nbfc',
            element: <BankAndNBFC />
        },
        {
            path: '/product-category',
            element: <ProductCategory />
        },
        {
            path: '/products',
            element: <Products />
        }
    ]
};

export default EditorRoutes;
