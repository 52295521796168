import { lazy } from 'react';
import AuthGuard from 'utils/route-guard/AuthGuard';
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';

const Account = Loadable(lazy(() => import('views/common/Account')));
const Dashboard = Loadable(lazy(() => import('views/dsaaccess/Dashboard')));
const Tab1 = Loadable(lazy(() => import('views/dsaaccess/Tab1')));
const Tab2 = Loadable(lazy(() => import('views/dsaaccess/Tab2')));
const Tab3 = Loadable(lazy(() => import('views/dsaaccess/Tab3')));
const Tab4 = Loadable(lazy(() => import('views/dsaaccess/Tab4')));
const Tab5 = Loadable(lazy(() => import('views/dsaaccess/Tab5')));
const Tab6 = Loadable(lazy(() => import('views/dsaaccess/Tab6')));

const DSAAccessRoutes = {
    path: '/',
    element: (
        <AuthGuard>
            <MainLayout />
        </AuthGuard>
    ),
    children: [
        {
            path: '/account',
            element: <Account />
        },
        {
            path: '/home',
            element: <Dashboard />
        },
        {
            path: '/dsaaccess-tab-1',
            element: <Tab1 />
        },
        {
            path: '/dsaaccess-tab-2',
            element: <Tab2 />
        },
        {
            path: '/dsaaccess-tab-3',
            element: <Tab3 />
        },
        {
            path: '/dsaaccess-tab-4',
            element: <Tab4 />
        },
        {
            path: '/dsaaccess-tab-5',
            element: <Tab5 />
        },
        {
            path: '/dsaaccess-tab-6',
            element: <Tab6 />
        }
    ]
};

export default DSAAccessRoutes;
