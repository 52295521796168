import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    studentDetails: {},
    studentDashDetails: {},
    studentDashAttendance: {},
    studentDashNotice: [],
    studentClasses: [],
    studentNotice: [],
    studentExams: [],
    studentResultDetails: {},
    studentAttendance: {},
    attendanceMonths: [],
    attendanceDays: [],
    sundaysOfTheMonth: [],
    attendanceSummary: {},
    sltResultExamDet: {}
};

export const studentSlice = createSlice({
    name: 'student',
    initialState,
    reducers: {
        setStudentDetails: (state, action) => {
            state.studentDetails = action.payload;
        },
        setStudentDashDetails: (state, action) => {
            state.studentDashDetails = action.payload;
        },
        setStudentDashAttendance: (state, action) => {
            state.studentDashAttendance = action.payload;
        },
        setStudentDashNotice: (state, action) => {
            state.studentDashNotice = action.payload;
        },
        setStudentNotice: (state, action) => {
            state.studentNotice = action.payload;
        },
        setStudentExams: (state, action) => {
            state.studentExams = action.payload;
        },
        setStudentResultDetails: (state, action) => {
            state.studentResultDetails = action.payload;
        },
        setStudentAttendance: (state, action) => {
            state.studentAttendance = action.payload;
        },
        setAttendanceMonths: (state, action) => {
            state.attendanceMonths = action.payload;
        },
        setAttendanceDays: (state, action) => {
            state.attendanceDays = action.payload;
        },
        setSundaysOfTheMonth: (state, action) => {
            state.sundaysOfTheMonth = action.payload;
        },
        setStudentClasses: (state, action) => {
            state.studentClasses = action.payload;
        },
        setAttendanceSummary: (state, action) => {
            state.attendanceSummary = action.payload;
        },
        setSltResultExamDet: (state, action) => {
            state.sltResultExamDet = action.payload;
        }
    }
});

// Action creators are generated for each case reducer function
export const {
    setStudentDetails,
    setStudentDashDetails,
    setStudentDashAttendance,
    setStudentDashNotice,
    setStudentNotice,
    setStudentExams,
    setStudentResultDetails,
    setStudentAttendance,
    setAttendanceMonths,
    setAttendanceDays,
    setSundaysOfTheMonth,
    setStudentClasses,
    setAttendanceSummary,
    setSltResultExamDet
} = studentSlice.actions;

export default studentSlice.reducer;
