import { Link as RouterLink } from 'react-router-dom';
import { Link, useMediaQuery } from '@mui/material';
import { DASHBOARD_PATH } from 'config';
import Logo from '../../../assets/images/growboox.png';

const LogoSection = () => {
    const isDesk = useMediaQuery('(min-width:1279px)');

    return (
        <>
            {isDesk ? (
                <Link component={RouterLink} to={DASHBOARD_PATH}>
                    <img src={Logo} style={{ width: 180 }} alt="Gowboox" />
                </Link>
            ) : (
                <Link component={RouterLink} to={DASHBOARD_PATH}>
                    <img src={Logo} style={{ width: 124, height: 30 }} alt="Growboox" />
                </Link>
            )}
        </>
    );
};
export default LogoSection;
