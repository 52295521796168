import ReactDOM from 'react-dom/client';

// third party
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';

// project imports
import App from 'App';
import { BASE_PATH } from 'config';
import * as serviceWorkerRegistration from 'serviceWorkerRegistration';
import { persister, store } from 'store';
// import reportWebVitals from './reportWebVitals';
import { ConfigProvider } from 'contexts/ConfigContext';

// auth provider
import { FirebaseProvider as AuthProvider } from 'contexts/FirebaseContext';

// style + assets
import 'assets/scss/style.scss';
import { setServiceWorkerInitialized, setServiceWorkerRegistration, setServiceWorkerUpdated } from 'store/slices/commonWP';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DndProvider } from 'react-dnd';

// ==============================|| REACT DOM RENDER  ||============================== //

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
    <DndProvider backend={HTML5Backend}>
        <Provider store={store}>
            <PersistGate loading={null} persistor={persister}>
                <ConfigProvider>
                    <BrowserRouter basename={BASE_PATH}>
                        <AuthProvider>
                            <App />
                        </AuthProvider>
                    </BrowserRouter>
                </ConfigProvider>
            </PersistGate>
        </Provider>
    </DndProvider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorkerRegistration.register({
    onSuccess: () => {
        store.dispatch(setServiceWorkerInitialized());
    },
    onUpdate: (registration) => {
        store.dispatch(setServiceWorkerRegistration(registration));
    }
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
