import { styled, useTheme } from '@mui/material/styles';
import { Button, Card, CardContent, Grid, Stack, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import AnimateButton from 'ui-component/extended/AnimateButton';

const CardStyle = styled(Card)(({ theme }) => ({
    background: theme.palette.mode === 'dark' ? theme.palette.dark[800] : theme.palette.secondary.light,
    marginTop: '16px',
    marginBottom: '16px',
    overflow: 'hidden',
    position: 'relative',
    '&:after': {
        content: '""',
        position: 'absolute',
        width: '200px',
        height: '200px',
        border: '19px solid ',
        borderColor: theme.palette.mode === 'dark' ? theme.palette.secondary.main : theme.palette.secondary.main,
        borderRadius: '50%',
        top: '32px',
        right: '-150px'
    },
    '&:before': {
        content: '""',
        position: 'absolute',
        width: '200px',
        height: '200px',
        border: '3px solid ',
        borderColor: theme.palette.mode === 'dark' ? theme.palette.secondary.main : theme.palette.secondary.main,
        borderRadius: '50%',
        top: '90px',
        right: '-70px'
    }
}));

const UpgradePlanCard = () => {
    const navigate = useNavigate();
    const theme = useTheme();

    return (
        <CardStyle>
            <CardContent>
                <Grid container direction="column" spacing={2}>
                    <Grid item>
                        <Typography variant="h4">Upgrade your plan</Typography>
                    </Grid>
                    {/* <Grid item>
                        <Typography
                            variant="subtitle2"
                            color={theme.palette.mode === 'dark' ? 'textSecondary' : 'grey.900'}
                            sx={{ opacity: theme.palette.mode === 'dark' ? 1 : 0.6 }}
                        >
                            50% discount for 1 years <br />
                            subscriptions.
                        </Typography>
                    </Grid> */}
                    <Grid item>
                        <Stack direction="row">
                            <AnimateButton>
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    onClick={() => navigate('/checkout', { replace: true })}
                                    sx={{ boxShadow: 'none', color: 'white' }}
                                >
                                    Purchase Now
                                </Button>
                            </AnimateButton>
                        </Stack>
                    </Grid>
                </Grid>
            </CardContent>
        </CardStyle>
    );
};

export default UpgradePlanCard;
