import {
    AssignmentTurnedIn,
    Call,
    Cancel,
    CurrencyRupee,
    Home,
    Inventory2,
    Payment,
    PendingActions,
    Source,
    Tty
} from '@mui/icons-material';
import { FormattedMessage } from 'react-intl';

const Telecaller = {
    id: 'demoaccess',
    type: 'group',
    children: [
        {
            id: 'home',
            title: <FormattedMessage id="home" />,
            type: 'item',
            url: '/home',
            icon: Home,
            breadcrumbs: false
        },
        {
            id: 'calling',
            title: <FormattedMessage id="calling" />,
            type: 'item',
            url: '/calling',
            icon: Tty,
            breadcrumbs: false
        },
        {
            id: 'follow-up',
            title: <FormattedMessage id="follow-up" />,
            type: 'item',
            url: '/follow-up',
            icon: Call,
            breadcrumbs: false
        },
        {
            id: 'all-cases',
            title: <FormattedMessage id="all-cases" />,
            type: 'item',
            url: '/all-cases',
            icon: Source,
            breadcrumbs: false
        },
        {
            id: 'inprocess',
            title: <FormattedMessage id="inprocess" />,
            type: 'item',
            url: '/inprocess',
            icon: PendingActions,
            breadcrumbs: false
        },
        {
            id: 'approved',
            title: <FormattedMessage id="approved" />,
            type: 'item',
            url: '/approved',
            icon: AssignmentTurnedIn,
            breadcrumbs: false
        },
        {
            id: 'rejected',
            title: <FormattedMessage id="rejected" />,
            type: 'item',
            url: '/rejected',
            icon: Cancel,
            breadcrumbs: false
        },
        {
            id: 'disbursed',
            title: <FormattedMessage id="disbursed" />,
            type: 'item',
            url: '/disbursed',
            icon: Payment,
            breadcrumbs: false
        },
        {
            id: 'payouts',
            title: <FormattedMessage id="payouts" />,
            type: 'item',
            url: '/payouts',
            icon: CurrencyRupee,
            breadcrumbs: false
        },
        {
            id: 'product-rates',
            title: <FormattedMessage id="product-rates" />,
            type: 'item',
            url: '/product-rates',
            icon: Inventory2,
            breadcrumbs: false
        }
    ]
};

export default Telecaller;
