import {
    AccountBalanceWallet,
    AdminPanelSettings,
    AssignmentTurnedIn,
    Cancel,
    CancelOutlined,
    Category,
    CurrencyRupee,
    CurrencyRupeeOutlined,
    Diversity3,
    Inventory2,
    Payment,
    PendingActions,
    SaveOutlined,
    Source,
    Tty
} from '@mui/icons-material';
import { List, ListItemButton, ListItemIcon, ListItemText, useTheme } from '@mui/material';
import useAuth from 'hooks/useAuth';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'store';
import { setSelectedMobileMenuItem } from 'store/slices/common';

export default function SelectedListItem({ handleTooltipOpen }) {
    const theme = useTheme();
    const dispatch = useDispatch();
    const { defaultRole } = useAuth();
    const { selectedMobileMenuItem } = useSelector((state) => state.common);

    const handleListItemClick = (event, index) => {
        dispatch(setSelectedMobileMenuItem(index));
        handleTooltipOpen();
    };

    const itemTarget = '_self';
    const listItemProps = (link) => ({
        component: React.forwardRef((props, ref) => <Link ref={ref} {...props} to={link} target={itemTarget} />)
    });

    const agentList = () => (
        <>
            <ListItemButton
                {...listItemProps('/saved-case')}
                selected={selectedMobileMenuItem === 0}
                onClick={(event) => handleListItemClick(event, 0)}
            >
                <ListItemIcon>
                    <SaveOutlined />
                </ListItemIcon>
                <ListItemText primary="Saved Case" />
            </ListItemButton>
            <ListItemButton
                {...listItemProps('/rejected')}
                selected={selectedMobileMenuItem === 1}
                onClick={(event) => handleListItemClick(event, 1)}
            >
                <ListItemIcon>
                    <CancelOutlined />
                </ListItemIcon>
                <ListItemText primary="Rejected" />
            </ListItemButton>
            <ListItemButton
                {...listItemProps('/disbursed')}
                selected={selectedMobileMenuItem === 2}
                onClick={(event) => handleListItemClick(event, 2)}
            >
                <ListItemIcon>
                    <CurrencyRupeeOutlined />
                </ListItemIcon>
                <ListItemText primary="Disbursed" />
            </ListItemButton>
        </>
    );

    const dsaList = () => (
        <>
            <ListItemButton
                {...listItemProps('/inprocess')}
                selected={selectedMobileMenuItem === 0}
                onClick={(event) => handleListItemClick(event, 0)}
            >
                <ListItemIcon>
                    <PendingActions />
                </ListItemIcon>
                <ListItemText primary={<FormattedMessage id="inprocess" />} />
            </ListItemButton>
            <ListItemButton
                {...listItemProps('/approved')}
                selected={selectedMobileMenuItem === 1}
                onClick={(event) => handleListItemClick(event, 1)}
            >
                <ListItemIcon>
                    <AssignmentTurnedIn />
                </ListItemIcon>
                <ListItemText primary={<FormattedMessage id="approved" />} />
            </ListItemButton>
            <ListItemButton
                {...listItemProps('/rejected')}
                selected={selectedMobileMenuItem === 2}
                onClick={(event) => handleListItemClick(event, 2)}
            >
                <ListItemIcon>
                    <Cancel />
                </ListItemIcon>
                <ListItemText primary={<FormattedMessage id="rejected" />} />
            </ListItemButton>
            <ListItemButton
                {...listItemProps('/disbursed')}
                selected={selectedMobileMenuItem === 3}
                onClick={(event) => handleListItemClick(event, 3)}
            >
                <ListItemIcon>
                    <Payment />
                </ListItemIcon>
                <ListItemText primary={<FormattedMessage id="disbursed" />} />
            </ListItemButton>
            <ListItemButton
                {...listItemProps('/telecalling')}
                selected={selectedMobileMenuItem === 4}
                onClick={(event) => handleListItemClick(event, 4)}
            >
                <ListItemIcon>
                    <Tty />
                </ListItemIcon>
                <ListItemText primary={<FormattedMessage id="telecalling" />} />
            </ListItemButton>
            <ListItemButton
                {...listItemProps('/credits')}
                selected={selectedMobileMenuItem === 5}
                onClick={(event) => handleListItemClick(event, 5)}
            >
                <ListItemIcon>
                    <AccountBalanceWallet />
                </ListItemIcon>
                <ListItemText primary={<FormattedMessage id="credits" />} />
            </ListItemButton>
            <ListItemButton
                {...listItemProps('/payouts')}
                selected={selectedMobileMenuItem === 6}
                onClick={(event) => handleListItemClick(event, 6)}
            >
                <ListItemIcon>
                    <CurrencyRupee />
                </ListItemIcon>
                <ListItemText primary={<FormattedMessage id="payouts" />} />
            </ListItemButton>
            <ListItemButton
                {...listItemProps('/products')}
                selected={selectedMobileMenuItem === 7}
                onClick={(event) => handleListItemClick(event, 7)}
            >
                <ListItemIcon>
                    <Inventory2 />
                </ListItemIcon>
                <ListItemText primary={<FormattedMessage id="products" />} />
            </ListItemButton>
            <ListItemButton
                {...listItemProps('/permission')}
                selected={selectedMobileMenuItem === 8}
                onClick={(event) => handleListItemClick(event, 8)}
            >
                <ListItemIcon>
                    <AdminPanelSettings />
                </ListItemIcon>
                <ListItemText primary={<FormattedMessage id="permission" />} />
            </ListItemButton>
        </>
    );

    const backofficeList = () => (
        <>
            <ListItemButton
                {...listItemProps('/inprocess')}
                selected={selectedMobileMenuItem === 0}
                onClick={(event) => handleListItemClick(event, 0)}
            >
                <ListItemIcon>
                    <PendingActions />
                </ListItemIcon>
                <ListItemText primary={<FormattedMessage id="inprocess" />} />
            </ListItemButton>
            <ListItemButton
                {...listItemProps('/approved')}
                selected={selectedMobileMenuItem === 1}
                onClick={(event) => handleListItemClick(event, 1)}
            >
                <ListItemIcon>
                    <AssignmentTurnedIn />
                </ListItemIcon>
                <ListItemText primary={<FormattedMessage id="approved" />} />
            </ListItemButton>
            <ListItemButton
                {...listItemProps('/rejected')}
                selected={selectedMobileMenuItem === 2}
                onClick={(event) => handleListItemClick(event, 2)}
            >
                <ListItemIcon>
                    <Cancel />
                </ListItemIcon>
                <ListItemText primary={<FormattedMessage id="rejected" />} />
            </ListItemButton>
            <ListItemButton
                {...listItemProps('/disbursed')}
                selected={selectedMobileMenuItem === 3}
                onClick={(event) => handleListItemClick(event, 3)}
            >
                <ListItemIcon>
                    <Payment />
                </ListItemIcon>
                <ListItemText primary={<FormattedMessage id="disbursed" />} />
            </ListItemButton>
            <ListItemButton
                {...listItemProps('/telecalling')}
                selected={selectedMobileMenuItem === 4}
                onClick={(event) => handleListItemClick(event, 4)}
            >
                <ListItemIcon>
                    <Tty />
                </ListItemIcon>
                <ListItemText primary={<FormattedMessage id="telecalling" />} />
            </ListItemButton>
            <ListItemButton
                {...listItemProps('/credits')}
                selected={selectedMobileMenuItem === 5}
                onClick={(event) => handleListItemClick(event, 5)}
            >
                <ListItemIcon>
                    <AccountBalanceWallet />
                </ListItemIcon>
                <ListItemText primary={<FormattedMessage id="credits" />} />
            </ListItemButton>
            <ListItemButton
                {...listItemProps('/payouts')}
                selected={selectedMobileMenuItem === 6}
                onClick={(event) => handleListItemClick(event, 6)}
            >
                <ListItemIcon>
                    <CurrencyRupee />
                </ListItemIcon>
                <ListItemText primary={<FormattedMessage id="payouts" />} />
            </ListItemButton>
            <ListItemButton
                {...listItemProps('/products')}
                selected={selectedMobileMenuItem === 7}
                onClick={(event) => handleListItemClick(event, 7)}
            >
                <ListItemIcon>
                    <Inventory2 />
                </ListItemIcon>
                <ListItemText primary={<FormattedMessage id="products" />} />
            </ListItemButton>
        </>
    );

    const televendorList = () => (
        <>
            <ListItemButton
                {...listItemProps('/televendor-tab-4')}
                selected={selectedMobileMenuItem === 0}
                onClick={(event) => handleListItemClick(event, 0)}
            >
                <ListItemIcon>
                    <Category />
                </ListItemIcon>
                <ListItemText primary={<FormattedMessage id="televendor-tab-4" />} />
            </ListItemButton>
            <ListItemButton
                {...listItemProps('/televendor-tab-5')}
                selected={selectedMobileMenuItem === 1}
                onClick={(event) => handleListItemClick(event, 1)}
            >
                <ListItemIcon>
                    <Category />
                </ListItemIcon>
                <ListItemText primary={<FormattedMessage id="televendor-tab-5" />} />
            </ListItemButton>
            <ListItemButton
                {...listItemProps('/televendor-tab-6')}
                selected={selectedMobileMenuItem === 2}
                onClick={(event) => handleListItemClick(event, 2)}
            >
                <ListItemIcon>
                    <Category />
                </ListItemIcon>
                <ListItemText primary={<FormattedMessage id="televendor-tab-6" />} />
            </ListItemButton>
        </>
    );

    const dsaaccessList = () => (
        <>
            <ListItemButton
                {...listItemProps('/dsaaccess-tab-4')}
                selected={selectedMobileMenuItem === 0}
                onClick={(event) => handleListItemClick(event, 0)}
            >
                <ListItemIcon>
                    <Category />
                </ListItemIcon>
                <ListItemText primary={<FormattedMessage id="dsaaccess-tab-4" />} />
            </ListItemButton>
            <ListItemButton
                {...listItemProps('/dsaaccess-tab-5')}
                selected={selectedMobileMenuItem === 1}
                onClick={(event) => handleListItemClick(event, 1)}
            >
                <ListItemIcon>
                    <Category />
                </ListItemIcon>
                <ListItemText primary={<FormattedMessage id="dsaaccess-tab-5" />} />
            </ListItemButton>
            <ListItemButton
                {...listItemProps('/dsaaccess-tab-6')}
                selected={selectedMobileMenuItem === 2}
                onClick={(event) => handleListItemClick(event, 2)}
            >
                <ListItemIcon>
                    <Category />
                </ListItemIcon>
                <ListItemText primary={<FormattedMessage id="dsaaccess-tab-6" />} />
            </ListItemButton>
        </>
    );

    const connectorList = () => (
        <>
            <ListItemButton
                {...listItemProps('/clients')}
                selected={selectedMobileMenuItem === 0}
                onClick={(event) => handleListItemClick(event, 0)}
            >
                <ListItemIcon>
                    <Diversity3 />
                </ListItemIcon>
                <ListItemText primary={<FormattedMessage id="clients" />} />
            </ListItemButton>
            <ListItemButton
                {...listItemProps('/inprocess')}
                selected={selectedMobileMenuItem === 1}
                onClick={(event) => handleListItemClick(event, 1)}
            >
                <ListItemIcon>
                    <PendingActions />
                </ListItemIcon>
                <ListItemText primary={<FormattedMessage id="inprocess" />} />
            </ListItemButton>
            <ListItemButton
                {...listItemProps('/approved')}
                selected={selectedMobileMenuItem === 2}
                onClick={(event) => handleListItemClick(event, 2)}
            >
                <ListItemIcon>
                    <AssignmentTurnedIn />
                </ListItemIcon>
                <ListItemText primary={<FormattedMessage id="approved" />} />
            </ListItemButton>
            <ListItemButton
                {...listItemProps('/rejected')}
                selected={selectedMobileMenuItem === 3}
                onClick={(event) => handleListItemClick(event, 3)}
            >
                <ListItemIcon>
                    <Cancel />
                </ListItemIcon>
                <ListItemText primary={<FormattedMessage id="rejected" />} />
            </ListItemButton>
            <ListItemButton
                {...listItemProps('/disbursed')}
                selected={selectedMobileMenuItem === 4}
                onClick={(event) => handleListItemClick(event, 4)}
            >
                <ListItemIcon>
                    <Payment />
                </ListItemIcon>
                <ListItemText primary={<FormattedMessage id="disbursed" />} />
            </ListItemButton>
            <ListItemButton
                {...listItemProps('/payouts')}
                selected={selectedMobileMenuItem === 5}
                onClick={(event) => handleListItemClick(event, 5)}
            >
                <ListItemIcon>
                    <CurrencyRupee />
                </ListItemIcon>
                <ListItemText primary={<FormattedMessage id="payouts" />} />
            </ListItemButton>
            <ListItemButton
                {...listItemProps('/product-rates')}
                selected={selectedMobileMenuItem === 6}
                onClick={(event) => handleListItemClick(event, 6)}
            >
                <ListItemIcon>
                    <Inventory2 />
                </ListItemIcon>
                <ListItemText primary={<FormattedMessage id="product-rates" />} />
            </ListItemButton>
            <ListItemButton
                {...listItemProps('/direct-cases')}
                selected={selectedMobileMenuItem === 7}
                onClick={(event) => handleListItemClick(event, 7)}
            >
                <ListItemIcon>
                    <Source />
                </ListItemIcon>
                <ListItemText primary={<FormattedMessage id="direct-cases" />} />
            </ListItemButton>
        </>
    );

    const demoaccessList = () => (
        <>
            <ListItemButton
                {...listItemProps('/inprocess')}
                selected={selectedMobileMenuItem === 0}
                onClick={(event) => handleListItemClick(event, 0)}
            >
                <ListItemIcon>
                    <PendingActions />
                </ListItemIcon>
                <ListItemText primary={<FormattedMessage id="inprocess" />} />
            </ListItemButton>
            <ListItemButton
                {...listItemProps('/approved')}
                selected={selectedMobileMenuItem === 1}
                onClick={(event) => handleListItemClick(event, 1)}
            >
                <ListItemIcon>
                    <AssignmentTurnedIn />
                </ListItemIcon>
                <ListItemText primary={<FormattedMessage id="approved" />} />
            </ListItemButton>
            <ListItemButton
                {...listItemProps('/rejected')}
                selected={selectedMobileMenuItem === 2}
                onClick={(event) => handleListItemClick(event, 2)}
            >
                <ListItemIcon>
                    <Cancel />
                </ListItemIcon>
                <ListItemText primary={<FormattedMessage id="rejected" />} />
            </ListItemButton>
            <ListItemButton
                {...listItemProps('/disbursed')}
                selected={selectedMobileMenuItem === 3}
                onClick={(event) => handleListItemClick(event, 3)}
            >
                <ListItemIcon>
                    <Payment />
                </ListItemIcon>
                <ListItemText primary={<FormattedMessage id="disbursed" />} />
            </ListItemButton>
            <ListItemButton
                {...listItemProps('/telecalling')}
                selected={selectedMobileMenuItem === 4}
                onClick={(event) => handleListItemClick(event, 4)}
            >
                <ListItemIcon>
                    <Tty />
                </ListItemIcon>
                <ListItemText primary={<FormattedMessage id="telecalling" />} />
            </ListItemButton>
            <ListItemButton
                {...listItemProps('/credits')}
                selected={selectedMobileMenuItem === 5}
                onClick={(event) => handleListItemClick(event, 5)}
            >
                <ListItemIcon>
                    <AccountBalanceWallet />
                </ListItemIcon>
                <ListItemText primary={<FormattedMessage id="credits" />} />
            </ListItemButton>
            <ListItemButton
                {...listItemProps('/payouts')}
                selected={selectedMobileMenuItem === 6}
                onClick={(event) => handleListItemClick(event, 6)}
            >
                <ListItemIcon>
                    <CurrencyRupee />
                </ListItemIcon>
                <ListItemText primary={<FormattedMessage id="payouts" />} />
            </ListItemButton>
            <ListItemButton
                {...listItemProps('/products')}
                selected={selectedMobileMenuItem === 7}
                onClick={(event) => handleListItemClick(event, 7)}
            >
                <ListItemIcon>
                    <Inventory2 />
                </ListItemIcon>
                <ListItemText primary={<FormattedMessage id="products" />} />
            </ListItemButton>
            <ListItemButton
                {...listItemProps('/permission')}
                selected={selectedMobileMenuItem === 8}
                onClick={(event) => handleListItemClick(event, 8)}
            >
                <ListItemIcon>
                    <AdminPanelSettings />
                </ListItemIcon>
                <ListItemText primary={<FormattedMessage id="permission" />} />
            </ListItemButton>
        </>
    );

    const telecallerList = () => (
        <>
            <ListItemButton
                {...listItemProps('/inprocess')}
                selected={selectedMobileMenuItem === 0}
                onClick={(event) => handleListItemClick(event, 0)}
            >
                <ListItemIcon>
                    <PendingActions />
                </ListItemIcon>
                <ListItemText primary={<FormattedMessage id="inprocess" />} />
            </ListItemButton>
            <ListItemButton
                {...listItemProps('/approved')}
                selected={selectedMobileMenuItem === 1}
                onClick={(event) => handleListItemClick(event, 1)}
            >
                <ListItemIcon>
                    <AssignmentTurnedIn />
                </ListItemIcon>
                <ListItemText primary={<FormattedMessage id="approved" />} />
            </ListItemButton>
            <ListItemButton
                {...listItemProps('/rejected')}
                selected={selectedMobileMenuItem === 2}
                onClick={(event) => handleListItemClick(event, 2)}
            >
                <ListItemIcon>
                    <Cancel />
                </ListItemIcon>
                <ListItemText primary={<FormattedMessage id="rejected" />} />
            </ListItemButton>
            <ListItemButton
                {...listItemProps('/disbursed')}
                selected={selectedMobileMenuItem === 3}
                onClick={(event) => handleListItemClick(event, 3)}
            >
                <ListItemIcon>
                    <Payment />
                </ListItemIcon>
                <ListItemText primary={<FormattedMessage id="disbursed" />} />
            </ListItemButton>
            <ListItemButton
                {...listItemProps('/product-rates')}
                selected={selectedMobileMenuItem === 4}
                onClick={(event) => handleListItemClick(event, 4)}
            >
                <ListItemIcon>
                    <Inventory2 />
                </ListItemIcon>
                <ListItemText primary={<FormattedMessage id="product-rates" />} />
            </ListItemButton>
            <ListItemButton
                {...listItemProps('/payouts')}
                selected={selectedMobileMenuItem === 6}
                onClick={(event) => handleListItemClick(event, 6)}
            >
                <ListItemIcon>
                    <CurrencyRupee />
                </ListItemIcon>
                <ListItemText primary={<FormattedMessage id="payouts" />} />
            </ListItemButton>
        </>
    );

    const customerList = () => (
        <>
            <ListItemButton
                {...listItemProps('/approved')}
                selected={selectedMobileMenuItem === 0}
                onClick={(event) => handleListItemClick(event, 0)}
            >
                <ListItemIcon>
                    <AssignmentTurnedIn />
                </ListItemIcon>
                <ListItemText primary={<FormattedMessage id="approved" />} />
            </ListItemButton>
            <ListItemButton
                {...listItemProps('/rejected')}
                selected={selectedMobileMenuItem === 1}
                onClick={(event) => handleListItemClick(event, 1)}
            >
                <ListItemIcon>
                    <Cancel />
                </ListItemIcon>
                <ListItemText primary={<FormattedMessage id="rejected" />} />
            </ListItemButton>
        </>
    );

    return (
        <List
            sx={{
                maxHeight: 300,
                overflow: 'hidden',
                overflowY: 'scroll',
                p: 0,
                '& .MuiListItemButton-root:nth-child(1)': { mt: 0 },
                '& .MuiListItemButton-root': {
                    bgcolor: theme.palette.primary.main,
                    p: 0.35,
                    mt: '7px',

                    borderRadius: `2px`,
                    ':hover': {
                        bgcolor: theme.palette.dark[800],
                        '.MuiListItemIcon-root .MuiSvgIcon-root': {
                            color: theme.palette.primary.light,
                            fontSize: 24,
                            pl: 0.3
                        },
                        '.MuiListItemText-primary': {
                            color: theme.palette.primary.light
                        }
                    },
                    '&.Mui-selected': {
                        ':hover': {
                            bgcolor: theme.palette.dark[800]
                        },

                        bgcolor: theme.palette.dark[800],
                        '.MuiListItemText-primary': {
                            color: theme.palette.primary.light
                        },
                        '.MuiListItemIcon-root .MuiSvgIcon-root': {
                            color: theme.palette.primary.light
                        }
                    },
                    '.MuiListItemText-primary': {
                        color: 'white'
                    },
                    '.MuiListItemIcon-root .MuiSvgIcon-root': {
                        color: 'white',
                        fontSize: 24,
                        pl: 0.3
                    },
                    '.MuiListItemIcon-root': {
                        minWidth: 28
                    }
                }
            }}
        >
            {defaultRole === 'dsa' && dsaList()}
            {defaultRole === 'backoffice' && backofficeList()}
            {defaultRole === 'televendor' && televendorList()}
            {defaultRole === 'dsaaccess' && dsaaccessList()}
            {defaultRole === 'connector' && connectorList()}
            {defaultRole === 'demoaccess' && demoaccessList()}
            {defaultRole === 'telecaller' && telecallerList()}
            {defaultRole === 'customer' && customerList()}
        </List>
    );
}
