/* eslint-disable import/prefer-default-export */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    apolloHeader: {}
};

export const authenticationSlice = createSlice({
    name: 'authentication',
    initialState,
    reducers: {
        setAolloHeaders: (state, action) => {
            state.apolloHeader = action.payload;
        }
    }
});

export const { setAolloHeaders } = authenticationSlice.actions;

export default authenticationSlice.reducer;
