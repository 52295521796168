import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    parentHomeChildren: {},
    parentHomeNotice: {},
    parentHomeExam: {},
    parentChildrens: [],
    parentDashDetails: [],
    selectedChildrenDetails: {}
};

export const parentSlice = createSlice({
    name: 'parent',
    initialState,
    reducers: {
        setParentHomeChildren: (state, action) => {
            state.parentHomeChildren = action.payload;
        },
        setParentHomeNotice: (state, action) => {
            state.parentHomeNotice = action.payload;
        },
        setParentHomeExam: (state, action) => {
            state.parentHomeExam = action.payload;
        },
        setParentChildrens: (state, action) => {
            state.parentChildrens = action.payload;
        },
        setParentDashDetails: (state, action) => {
            state.parentDashDetails = action.payload;
        },
        setSelectedChildrenDetails: (state, action) => {
            state.selectedChildrenDetails = action.payload;
        }
    }
});

// Action creators are generated for each case reducer function
export const {
    setParentHomeChildren,
    setParentHomeNotice,
    setParentHomeExam,
    setParentChildrens,
    setParentDashDetails,
    setSelectedChildrenDetails
} = parentSlice.actions;

export default parentSlice.reducer;
