import { lazy } from 'react';
import AuthGuard from 'utils/route-guard/AuthGuard';
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import Pricing from 'views/pricing';
import MinimalLayout from 'layout/MinimalLayout';

// const AnonUserHome = Loadable(lazy(() => import('views/PBXCMP/anon-user/Home')));
// const AnonUserSchools = Loadable(lazy(() => import('views/PBXCMP/anon-user/Schools')));
const Profile = Loadable(lazy(() => import('views/common/Profile')));
const Account = Loadable(lazy(() => import('views/common/Account')));
const AboutUs = Loadable(lazy(() => import('views/authentication/authentication/AboutUs')));
const Landing = Loadable(lazy(() => import('views/landing')));
const Checkout = Loadable(lazy(() => import('views/checkout')));
const DemoRequest = Loadable(lazy(() => import('views/demorequest')));

const AnonymousUserRoutes = {
    path: '/',
    element: (
        <AuthGuard>
            <MinimalLayout />
        </AuthGuard>
    ),
    children: [
        {
            path: '/account',
            element: <Account />
        },
        {
            path: '/profile',
            element: <Profile />
        },
        {
            path: '/checkout',
            element: <Checkout />
        },
        {
            path: '/demo-request',
            element: <DemoRequest />
        }
    ]
};

export default AnonymousUserRoutes;
