import useAuth from './useAuth';
import anonymoususer from 'menu-items/AnonymousUser';
import dsa from 'menu-items/DSA';
import connector from 'menu-items/Connector';
import admin from 'menu-items/Admin';
import editor from 'menu-items/Editor';
import backoffice from 'menu-items/BackOffice';
import customer from 'menu-items/Customer';
import demoaccess from 'menu-items/DemoAccess';
import telecaller from 'menu-items/Telecaller';
import televendor from 'menu-items/Televendor';
import dsaaccess from 'menu-items/DSAAccess';

function useMenuItems() {
    const { defaultRole, isLoggedIn, user } = useAuth();
    if (defaultRole === 'anon' || !user.isEmailVerfied) {
        return {
            items: [anonymoususer]
        };
    }
    if (defaultRole === 'demoaccess' && isLoggedIn !== false && user.isEmailVerfied) {
        return {
            items: [demoaccess]
        };
    }
    if (defaultRole === 'dsa' && isLoggedIn !== false && user.isEmailVerfied) {
        return {
            items: [dsa]
        };
    }
    if (defaultRole === 'televendor' && isLoggedIn !== false && user.isEmailVerfied) {
        return {
            items: [televendor]
        };
    }
    if (defaultRole === 'dsaaccess' && isLoggedIn !== false && user.isEmailVerfied) {
        return {
            items: [dsaaccess]
        };
    }
    if (defaultRole === 'connector' && isLoggedIn !== false && user.isEmailVerfied) {
        return {
            items: [connector]
        };
    }
    if (defaultRole === 'customer' && isLoggedIn !== false && user.isEmailVerfied) {
        return {
            items: [customer]
        };
    }
    if (defaultRole === 'telecaller' && isLoggedIn !== false && user.isEmailVerfied) {
        return {
            items: [telecaller]
        };
    }
    if (defaultRole === 'admin' && isLoggedIn !== false && user.isEmailVerfied) {
        return {
            items: [admin]
        };
    }
    if (defaultRole === 'editor' && isLoggedIn !== false && user.isEmailVerfied) {
        return {
            items: [editor]
        };
    }
    if (defaultRole === 'backoffice' && isLoggedIn !== false && user.isEmailVerfied) {
        return {
            items: [backoffice]
        };
    }
    return {
        items: [anonymoususer]
    };
}

export default useMenuItems;
