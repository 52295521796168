import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import Container from 'component/Container';
import { useNavigate } from 'react-router-dom';
import growboox from 'assets/images/growboox.png';
import useAuth from 'hooks/useAuth';

const Footer = () => {
    const { isLoggedIn } = useAuth();
    const theme = useTheme();
    const navigate = useNavigate();
    const { mode } = theme.palette;

    return (
        <Container paddingY={4}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Box
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                        width={1}
                        flexDirection={{ xs: 'column', sm: 'row' }}
                    >
                        <Box display="flex" component="a" href="/" title="theFront" width={80}>
                            <Box component="img" src={growboox} height={1} width={1} />
                        </Box>
                        <Box display="flex" flexWrap="wrap" alignItems="center" justifyContent="center">
                            <Box marginTop={1} marginRight={2}>
                                <Link underline="none" component="a" href="/" color="text.primary" variant="subtitle2">
                                    Home
                                </Link>
                            </Box>
                            <Box marginTop={1} marginRight={2}>
                                <Link underline="none" component="a" href="/about-us" color="text.primary" variant="subtitle2">
                                    About
                                </Link>
                            </Box>
                            <Box marginTop={1} marginRight={2}>
                                <Link underline="none" component="a" href="/privacy-policies" color="text.primary" variant="subtitle2">
                                    Privacy policy
                                </Link>
                            </Box>
                            <Box marginTop={1} marginRight={2}>
                                <Link underline="none" component="a" href="/refund-policies" color="text.primary" variant="subtitle2">
                                    Refund policy
                                </Link>
                            </Box>
                            <Box marginTop={1} marginRight={2}>
                                <Link underline="none" component="a" href="/contact-us" color="text.primary" variant="subtitle2">
                                    Contact us
                                </Link>
                            </Box>
                            <Box marginTop={1}>
                                <Button
                                    variant="outlined"
                                    color="primary"
                                    // component="a"
                                    // target="blank"
                                    // href="https://mui.com/store/items/the-front-landing-page/"
                                    size="small"
                                    onClick={() => navigate(isLoggedIn ? '/checkout' : '/register', { replace: true })}
                                >
                                    Purchase now
                                </Button>
                            </Box>
                        </Box>
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <Typography align="center" variant="subtitle2" color="text.secondary" gutterBottom>
                        &copy; XSoftex 2023, All rights reserved
                    </Typography>
                    <Typography align="center" variant="caption" color="text.secondary" component="p">
                        When you visit or interact with our sites, services or tools, we or our authorised service providers may use cookies
                        for storing information to help provide you with a better, faster and safer experience.
                    </Typography>
                </Grid>
            </Grid>
        </Container>
    );
};

export default Footer;
