import { AssignmentInd, Home, List, MenuBookOutlined } from '@mui/icons-material';
import { FormattedMessage } from 'react-intl';

const admin = {
    id: 'admin',
    type: 'group',
    children: [
        {
            id: 'home',
            title: <FormattedMessage id="home" />,
            type: 'item',
            url: '/home',
            icon: Home,
            breadcrumbs: false
        },
        {
            id: 'dsa-list',
            title: <FormattedMessage id="dsa-list" />,
            type: 'item',
            url: '/dsa-list',
            icon: List,
            breadcrumbs: false
        },
        {
            id: 'editors',
            title: <FormattedMessage id="editors" />,
            type: 'item',
            url: '/editors',
            icon: MenuBookOutlined,
            breadcrumbs: false
        },
        {
            id: 'role-assign',
            title: <FormattedMessage id="role-assign" />,
            type: 'item',
            url: '/role-assign',
            icon: AssignmentInd,
            breadcrumbs: false
        }
    ]
};

export default admin;
