/* eslint-disable import/prefer-default-export */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    idToken: '',
    role: 'pbxmgmtfree',
    uid: '',
    serviceWorkerInitialized: false,
    serviceWorkerUpdated: false,
    serviceWorkerRegistration: null,
    csvExportHeader: [],
    csvExportData: [],
    childrenDashDetails: [],
    cartItemList: [],
    checkedItemList: []
};

export const commonWPSlice = createSlice({
    name: 'managment',
    initialState,
    reducers: {
        setIdToken: (state, action) => {
            state.idToken = action.payload;
        },
        setRole: (state, action) => {
            state.role = action.payload;
        },
        setUid: (state, action) => {
            state.uid = action.payload;
        },
        setServiceWorkerInitialized: (state, action) => {
            state.serviceWorkerInitialized = !state.serviceWorkerInitialized;
        },
        setServiceWorkerUpdated: (state, action) => {
            state.serviceWorkerUpdated = action.payload;
        },
        setServiceWorkerRegistration: (state, action) => {
            state.serviceWorkerUpdated = !state.serviceWorkerUpdated;
            state.serviceWorkerRegistration = action.payload;
        },
        setCSVExportHeader: (state, action) => {
            state.csvExportHeader = action.payload;
        },
        setCSVExportData: (state, action) => {
            state.csvExportData = action.payload;
        },
        setChildrenDashDetails: (state, action) => {
            state.childrenDashDetails = action.payload;
        },
        setCartItemList: (state, action) => {
            state.cartItemList = action.payload;
        },
        setCheckedItemList: (state, action) => {
            state.checkedItemList = action.payload;
        }
    }
});

export const {
    setIdToken,
    setRole,
    setUid,
    setServiceWorkerInitialized,
    setServiceWorkerUpdated,
    setServiceWorkerRegistration,
    setCSVExportHeader,
    setCSVExportData,
    setChildrenDashDetails,
    setCartItemList,
    setCheckedItemList
} = commonWPSlice.actions;

export default commonWPSlice.reducer;
