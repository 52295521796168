import { createSelector, createSlice } from '@reduxjs/toolkit';
import { sortBy } from 'lodash';
import { dispatch, store } from '../index';
import axios from 'utils/axios';

const initialState = {
    commonNotices: {},
    commonExams: [],
    states: [],
    error: null,
    cities: [],
    findSchoolsList: [],
    selectedAccordianId: null,
    isUpdated: false,
    formatedDocList: [],
    isRecordInserted: false,
    isSubjectOfExamUpdated: false,
    selectedSubIdOfSubjectOfExam: '',
    selectedMobileMenuItem: 0,
    isDummy: false,
    // apolloHeader: {},
    isStudentDetailsEmpty: true,
    studentProfileDialog: {},
    classesListForSec: [],
    secGaurdDashschoolName: '',
    reset: false,

    page: 0,
    rowsPerPage: 10,
    tableData: [],
    filteredTableData: [],
    schoolName: '',
    essenFeeId: '',
    selectedSubjectInfo: {},
    selectedChildrenDetails: {},
    selectedVehicleDetails: {},
    securityGaurdDashDetails: {},
    sltClassRoomDet: {},
    sltIdData: {},
    sltEmployeeDet: {},
    sltCreditDet: {},
    sltClientDet: {},
    dashInfo: {},
    connectorDSAList: [],
    connectorInfo: {}
};

export const commonSlice = createSlice({
    name: 'common',
    initialState,
    reducers: {
        setCommonNotices: (state, action) => {
            state.commonNotices = action.payload;
        },
        setCommonExams: (state, action) => {
            state.commonExams = action.payload;
        },
        setStates: (state, action) => {
            state.states = action.payload;
        },
        setCities: (state, action) => {
            state.cities = action.payload;
        },
        hasError(state, action) {
            state.error = action.payload;
        },
        setFindSchoolsList(state, action) {
            state.findSchoolsList = action.payload;
        },
        setSelectedAccordianId(state, action) {
            state.selectedAccordianId = action.payload;
        },
        setIsUpdated: (state, action) => {
            state.isUpdated = action.payload;
        },
        setFormatedDocList: (state, action) => {
            state.formatedDocList = action.payload;
        },
        setIsRecordInserted: (state, action) => {
            state.isRecordInserted = action.payload;
        },
        setIsSubjectOfExamUpdated: (state, action) => {
            state.isSubjectOfExamUpdated = action.payload;
        },
        setSelectedSubIdOfSubjectOfExam: (state, action) => {
            state.selectedSubIdOfSubjectOfExam = action.payload;
        },
        setSelectedMobileMenuItem: (state, action) => {
            state.selectedMobileMenuItem = action.payload;
        },
        setIsDummy: (state, action) => {
            state.isDummy = action.payload;
        },
        setIsStudentDetailsEmpty: (state, action) => {
            state.isStudentDetailsEmpty = action.payload;
        },
        setStudentProfileDialog: (state, action) => {
            state.studentProfileDialog = action.payload;
        },
        setClassesListForSec: (state, action) => {
            state.classesListForSec = action.payload;
        },
        setSecGaurdDashschoolName: (state, action) => {
            state.secGaurdDashschoolName = action.payload;
        },
        setReset: (state, action) => {
            state.reset = action.payload;
        },
        setPage: (state, action) => {
            state.page = action.payload;
        },
        setRowsPerPage: (state, action) => {
            state.rowsPerPage = action.payload;
        },
        setTableData: (state, action) => {
            state.tableData = action.payload;
        },
        setFilteredTableData: (state, action) => {
            state.filteredTableData = action.payload;
        },
        setSchoolName: (state, action) => {
            state.schoolName = action.payload;
        },
        setEssenFeeId: (state, action) => {
            state.essenFeeId = action.payload;
        },
        setSelectedSubjectInfo: (state, action) => {
            state.selectedSubjectInfo = action.payload;
        },
        setSelectedChildrenDetails: (state, action) => {
            state.selectedChildrenDetails = action.payload;
        },
        setSelectedVehicleDetails: (state, action) => {
            state.selectedVehicleDetails = action.payload;
        },
        setSecurityGaurdDashDetails: (state, action) => {
            state.securityGaurdDashDetails = action.payload;
        },
        setSltClassRoomDet: (state, action) => {
            state.sltClassRoomDet = action.payload;
        },
        setSltIdData: (state, action) => {
            state.sltIdData = action.payload;
        },
        setSltEmployeeDet: (state, action) => {
            state.sltEmployeeDet = action.payload;
        },
        setSltCreditDet: (state, action) => {
            state.sltCreditDet = action.payload;
        },
        setSltClientDet: (state, action) => {
            state.sltClientDet = action.payload;
        },
        setDashInfo: (state, action) => {
            state.dashInfo = action.payload;
        },
        setConnectorDSAList: (state, action) => {
            state.connectorDSAList = action.payload;
        },
        setConnectorInfo: (state, action) => {
            state.connectorInfo = action.payload;
        }
    }
});

// Action creators are generated for each case reducer function
export const {
    setCommonNotices,
    setCommonExams,
    setFindSchoolsList,
    setSelectedAccordianId,
    setIsUpdated,
    setFormatedDocList,
    setIsRecordInserted,
    setIsSubjectOfExamUpdated,
    setSelectedSubIdOfSubjectOfExam,
    setSelectedMobileMenuItem,
    setIsDummy,
    setIsStudentDetailsEmpty,
    setStudentProfileDialog,
    setClassesListForSec,
    setSecGaurdDashschoolName,
    setReset,
    setPage,
    setRowsPerPage,
    setTableData,
    setFilteredTableData,
    setSchoolName,
    setEssenFeeId,
    setSelectedSubjectInfo,
    setSelectedChildrenDetails,
    setSelectedVehicleDetails,
    setSecurityGaurdDashDetails,
    setSltClassRoomDet,
    setSltIdData,
    setSltEmployeeDet,
    setSltCreditDet,
    setSltClientDet,
    setDashInfo,
    setConnectorDSAList,
    setConnectorInfo
} = commonSlice.actions;

export default commonSlice.reducer;

export function setStates() {
    return async () => {
        try {
            const response = await axios.get('https://api.countrystatecity.in/v1/countries/IN/states', {
                headers: {
                    'X-CSCAPI-KEY': 'QzdNejlFS3RGdnhGSEdaRTRhQ1dmVlRuQ2p4MHM5WFJaeWdzR1E5TA=='
                }
            });

            if (response.data) {
                console.log('State List', response.data);
                const stateList = [];
                response.data.map((item) =>
                    stateList.push({
                        stateId: item.id,
                        stateName: item.name,
                        stateIso2: item.iso2
                    })
                );
                dispatch(commonSlice.actions.setStates(stateList));
            }
        } catch (error) {
            dispatch(commonSlice.actions.hasError(error));
        }
    };
}

const selectCounterValue = createSelector(
    (state) => state.common,
    (common) => common.states
);

export function setCities(stateCode) {
    return async () => {
        try {
            const response = await axios.get(
                `https://api.countrystatecity.in/v1/countries/IN/states/${
                    selectCounterValue(store.getState()).find((item) => item.stateId === stateCode).stateIso2
                }/cities`,
                {
                    headers: {
                        'X-CSCAPI-KEY': 'QzdNejlFS3RGdnhGSEdaRTRhQ1dmVlRuQ2p4MHM5WFJaeWdzR1E5TA=='
                    }
                }
            );
            if (response.data) {
                const districtList = [];
                response.data.map((item) =>
                    districtList.push({
                        districtId: item.id,
                        districtName: item.name
                    })
                );
                dispatch(commonSlice.actions.setCities(districtList));
            }
        } catch (error) {
            dispatch(commonSlice.actions.hasError(error));
        }
    };
}
