import { lazy } from 'react';
import AuthGuard from 'utils/route-guard/AuthGuard';
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';

const Account = Loadable(lazy(() => import('views/common/Account')));
const Dashboard = Loadable(lazy(() => import('views/demoaccess/Dashboard')));
const Leads = Loadable(lazy(() => import('views/demoaccess/Leads')));
const InProcess = Loadable(lazy(() => import('views/demoaccess/InProcess')));
const Approved = Loadable(lazy(() => import('views/demoaccess/Approved')));
const Disbursed = Loadable(lazy(() => import('views/demoaccess/Disbursed')));
const Employees = Loadable(lazy(() => import('views/demoaccess/Employees')));
const Settlement = Loadable(lazy(() => import('views/demoaccess/Payouts')));
const Products = Loadable(lazy(() => import('views/demoaccess/Products')));
const Recharge = Loadable(lazy(() => import('views/demoaccess/Credits')));
const Rejected = Loadable(lazy(() => import('views/demoaccess/Rejected')));
const AllCases = Loadable(lazy(() => import('views/demoaccess/AllCases')));
const Permission = Loadable(lazy(() => import('views/demoaccess/Permission')));
const Telecalling = Loadable(lazy(() => import('views/demoaccess/Telecalling')));

const DemoAccessRoutes = {
    path: '/',
    element: (
        <AuthGuard>
            <MainLayout />
        </AuthGuard>
    ),
    children: [
        {
            path: '/account',
            element: <Account />
        },
        {
            path: '/home',
            element: <Dashboard />
        },
        {
            path: '/leads',
            element: <Leads />
        },
        {
            path: '/all-cases',
            element: <AllCases />
        },
        {
            path: '/inprocess',
            element: <InProcess />
        },
        {
            path: '/approved',
            element: <Approved />
        },
        {
            path: '/rejected',
            element: <Rejected />
        },
        {
            path: '/disbursed',
            element: <Disbursed />
        },
        {
            path: '/telecalling',
            element: <Telecalling />
        },
        {
            path: '/employees',
            element: <Employees />
        },
        {
            path: '/payouts',
            element: <Settlement />
        },
        {
            path: '/products',
            element: <Products />
        },
        {
            path: '/credits',
            element: <Recharge />
        },
        {
            path: '/permission',
            element: <Permission />
        }
    ]
};

export default DemoAccessRoutes;
