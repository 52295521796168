import { lazy } from 'react';
import { useRoutes } from 'react-router-dom';
import useAuth from 'hooks/useAuth';
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import GuestGuard from 'utils/route-guard/GuestGuard';
import Error from 'views/common/Error';
import AdminRoutes from './AdminRoutes';
import ConnectorRoutes from './ConnectorRoutes';
import AnonymousUserRoute from './AnonymousUserRoute';
import DSARoutes from './DSARoutes';
import EditorRoutes from './EditorRoutes';
import LoginRoutes from './LoginRoutes';
import BackOfficeRoutes from './BackOfficeRoutes';
import CustomerRoutes from './CustomerRoutes';
import DemoAccessRoutes from './DemoAccessRoutes';
import TelecallerRoutes from './TelecallerRoutes';
import TelevendorRoutes from './TelevendorRoutes';
import DSAAccessRoutes from './DSAAccessRoutes';

const VerifyEmail = Loadable(lazy(() => import('views/authentication/authentication/VerifyEmail')));
const Action = Loadable(lazy(() => import('views/common/Action')));
const AuthLogin = Loadable(lazy(() => import('views/authentication/authentication/Login')));
const Landing = Loadable(lazy(() => import('views/landing')));

export default function ThemeRoutes() {
    const { defaultRole, isLoggedIn, user } = useAuth();
    const anonroutes = useRoutes([
        {
            path: '/',
            element: (
                <GuestGuard>
                    <Landing />
                </GuestGuard>
            )
        },
        { path: '*', exact: true, element: <Error /> },
        {
            path: '/verifyemail',
            element: <VerifyEmail />
        },
        {
            path: '/accountverified',
            element: <Action />
        },
        LoginRoutes,
        AnonymousUserRoute
    ]);
    const demoaccessroutes = useRoutes([
        {
            path: '/',
            element: (
                <GuestGuard>
                    <MainLayout />
                </GuestGuard>
            )
        },
        { path: '*', exact: true, element: <Error /> },
        LoginRoutes,
        DemoAccessRoutes
    ]);
    const dsaroutes = useRoutes([
        {
            path: '/',
            element: (
                <GuestGuard>
                    <MainLayout />
                </GuestGuard>
            )
        },
        { path: '*', exact: true, element: <Error /> },
        LoginRoutes,
        DSARoutes
    ]);
    const televendorroutes = useRoutes([
        {
            path: '/',
            element: (
                <GuestGuard>
                    <MainLayout />
                </GuestGuard>
            )
        },
        { path: '*', exact: true, element: <Error /> },
        LoginRoutes,
        TelevendorRoutes
    ]);
    const dsaaccessroutes = useRoutes([
        {
            path: '/',
            element: (
                <GuestGuard>
                    <MainLayout />
                </GuestGuard>
            )
        },
        { path: '*', exact: true, element: <Error /> },
        LoginRoutes,
        DSAAccessRoutes
    ]);
    const connectorroutes = useRoutes([
        {
            path: '/',
            element: (
                <GuestGuard>
                    <MainLayout />
                </GuestGuard>
            )
        },
        { path: '*', exact: true, element: <Error /> },
        LoginRoutes,
        ConnectorRoutes
    ]);
    const customerroutes = useRoutes([
        {
            path: '/',
            element: (
                <GuestGuard>
                    <MainLayout />
                </GuestGuard>
            )
        },
        { path: '*', exact: true, element: <Error /> },
        LoginRoutes,
        CustomerRoutes
    ]);
    const telecallerroutes = useRoutes([
        {
            path: '/',
            element: (
                <GuestGuard>
                    <MainLayout />
                </GuestGuard>
            )
        },
        { path: '*', exact: true, element: <Error /> },
        LoginRoutes,
        TelecallerRoutes
    ]);
    const backofficeroutes = useRoutes([
        {
            path: '/',
            element: (
                <GuestGuard>
                    <MainLayout />
                </GuestGuard>
            )
        },
        { path: '*', exact: true, element: <Error /> },
        LoginRoutes,
        BackOfficeRoutes
    ]);
    const adminroutes = useRoutes([
        {
            path: '/',
            element: (
                <GuestGuard>
                    <MainLayout />
                </GuestGuard>
            )
        },
        { path: '*', exact: true, element: <Error /> },
        LoginRoutes,
        AdminRoutes
    ]);
    const editorroutes = useRoutes([
        {
            path: '/',
            element: (
                <GuestGuard>
                    <MainLayout />
                </GuestGuard>
            )
        },
        { path: '*', exact: true, element: <Error /> },
        LoginRoutes,
        EditorRoutes
    ]);
    if (defaultRole === 'anon' || !user.isEmailVerfied) {
        return anonroutes;
    }
    if (defaultRole === 'demoaccess' && isLoggedIn !== false && user.isEmailVerfied) {
        return demoaccessroutes;
    }
    if (defaultRole === 'dsa' && isLoggedIn !== false && user.isEmailVerfied) {
        return dsaroutes;
    }
    if (defaultRole === 'televendor' && isLoggedIn !== false && user.isEmailVerfied) {
        return televendorroutes;
    }
    if (defaultRole === 'dsaaccess' && isLoggedIn !== false && user.isEmailVerfied) {
        return dsaaccessroutes;
    }
    if (defaultRole === 'connector' && isLoggedIn !== false && user.isEmailVerfied) {
        return connectorroutes;
    }
    if (defaultRole === 'telecaller' && isLoggedIn !== false && user.isEmailVerfied) {
        return telecallerroutes;
    }
    if (defaultRole === 'customer' && isLoggedIn !== false && user.isEmailVerfied) {
        return customerroutes;
    }
    if (defaultRole === 'backoffice' && isLoggedIn !== false && user.isEmailVerfied) {
        return backofficeroutes;
    }
    if (defaultRole === 'admin' && isLoggedIn !== false && user.isEmailVerfied) {
        return adminroutes;
    }
    if (defaultRole === 'editor' && isLoggedIn !== false && user.isEmailVerfied) {
        return editorroutes;
    }
    return anonroutes;
}
